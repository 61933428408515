import {
  formatDateTime,
  ToastContext,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext } from "react";

import { ClinicianUnavailability } from "src/models/clinicianUnavailability";
import { useDeactivateClinicianUnavailabilityMutation } from "src/queries/clinicianUnavailability";

interface IProps {
  open: boolean;
  onClose: () => void;
  unavailability: ClinicianUnavailability;
}
const DeactivateUnavailability = ({
  open,
  onClose,
  unavailability,
}: IProps) => {
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: deactivateUnavailability } =
    useDeactivateClinicianUnavailabilityMutation(unavailability.id);

  const handleDeactivateUnavailability = async () => {
    try {
      await deactivateUnavailability();
      addToast("Unavailability deactivated successfully", "success");
      onClose();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "NOT_FOUND"
      ) {
        addToast("Could not find unavailability block", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "INACTIVE"
      ) {
        addToast("Unavailability block is already inactive", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Unavailability</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Are you sure you want to remove the unavailability block for{" "}
          {unavailability.clinicianName} from{" "}
          {formatDateTime(unavailability.startAt)} to{" "}
          {formatDateTime(unavailability.endAt)}?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={handleDeactivateUnavailability}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeactivateUnavailability;
