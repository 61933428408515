import {
  AutocompleteField,
  DateField,
  SubmitButton,
  formatDateTime,
  sortByKey,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import axios from "axios";
import { formatISO, parseISO, subDays } from "date-fns";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { Activity } from "src/models";
import { useActivitiesQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "activity" | "timestamp";

export enum ActivityType {
  APPOINTMENT_CREDIT_CANCELLED = "APPOINTMENT_CREDIT_CANCELLED",
  APPOINTMENT_CREDIT_CREATED = "APPOINTMENT_CREDIT_CREATED",
  APPOINTMENT_CREDIT_UPDATED = "APPOINTMENT_CREDIT_UPDATED",
  APPOINTMENT_CANCELLED = "APPOINTMENT_CANCELLED",
  APPOINTMENT_EDITED = "APPOINTMENT_EDITED",
  APPOINTMENT_LENGTH_CREATED = "APPOINTMENT_LENGTH_CREATED",
  APPOINTMENT_LENGTH_UPDATED = "APPOINTMENT_LENGTH_UPDATED",
  APPOINTMENT_PRICE_ALTERED = "APPOINTMENT_PRICE_ALTERED",
  CLINICIAN_AVAILABILITY_UPDATED = "CLINICIAN_AVAILABILITY_UPDATED",
  CLINICIAN_AVAILABILITY_REMOVED = "CLINICIAN_AVAILABILITY_REMOVED",
  CLINICIAN_RECURRING_AVAILABILITY_UPDATED = "CLINICIAN_RECURRING_AVAILABILITY_UPDATED",
  CLINICIAN_RECURRING_AVAILABILITY_REMOVED = "CLINICIAN_RECURRING_AVAILABILITY_REMOVED",
  CLINICIAN_CREATED = "CLINICIAN_CREATED",
  CLINICIAN_UNAVAILABILITY_CREATED = "CLINICIAN_UNAVAILABILITY_CREATED",
  CLINICIAN_UNAVAILABILITY_REMOVED = "CLINICIAN_UNAVAILABILITY_REMOVED",
  CLINICIAN_UPDATED = "CLINICIAN_UPDATED",
  EMAIL_ISSUE_UPDATED = "EMAIL_ISSUE_UPDATED",
  FP10_CREATED = "FP10_CREATED",
  FP10_DESTROYED = "FP10_DESTROYED",
  OUT_OF_STOCK_LISTING_CREATED = "OUT_OF_STOCK_LISTING_CREATED",
  OUT_OF_STOCK_LISTING_UPDATED = "OUT_OF_STOCK_LISTING_UPDATED",
  OUT_OF_STOCK_LISTING_CLOSED = "OUT_OF_STOCK_LISTING_CLOSED",
  PRESCRIBER_CREATED = "PRESCRIBER_CREATED",
  PRESCRIBER_SIGNATURE_ADDED = "PRESCRIBER_SIGNATURE_ADDED",
  PRESCRIBER_UPDATED = "PRESCRIBER_UPDATED",
  PRODUCT_GROUP_ADDED = "PRODUCT_GROUP_ADDED",
  PRODUCT_GROUP_UPDATED = "PRODUCT_GROUP_UPDATED",
  PRODUCT_GROUP_DISCONTINUED = "PRODUCT_GROUP_DISCONTINUED",
  PRODUCT_GROUP_REACTIVATED = "PRODUCT_GROUP_REACTIVATED",
  SPECIALITY_GROUP_CREATED = "SPECIALITY_GROUP_CREATED",
  SPECIALITY_GROUP_UPDATED = "SPECIALITY_GROUP_UPDATED",
  STAFF_CREATED = "STAFF_CREATED",
  STAFF_DEACTIVATED = "STAFF_DEACTIVATED",
  STAFF_DISABLED = "STAFF_DISABLED",
  STAFF_ENABLED = "STAFF_ENABLED",
  STAFF_UPDATED = "STAFF_UPDATED",
  SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED",
  SUBSCRIPTION_CREATED = "SUBSCRIPTION_CREATED",
  SUBSCRIPTION_NOTICE_PERIOD_OVERRIDE = "SUBSCRIPTION_NOTICE_PERIOD_OVERRIDE",
  TRAVEL_LETTER_APPROVED = "TRAVEL_LETTER_APPROVED",
  TRAVEL_LETTER_ASSIGNED = "TRAVEL_LETTER_ASSIGNED",
  TRAVEL_LETTER_ASSIGNMENT_REMOVED = "TRAVEL_LETTER_ASSIGNMENT_REMOVED",
  TRAVEL_LETTER_CREATED = "TRAVEL_LETTER_CREATED",
  TRAVEL_LETTER_CANCELLED = "TRAVEL_LETTER_CANCELLED",
  TRAVEL_LETTER_GENERATED = "TRAVEL_LETTER_GENERATED",
  TRAVEL_LETTER_UPDATED = "TRAVEL_LETTER_UPDATED",
  TRAVEL_LETTER_UPLOADED = "TRAVEL_LETTER_UPLOADED",
}

export const activityTypeList = (): { value: ActivityType }[] => {
  return Object.values(ActivityType).map((activity) => ({
    value: activity,
  }));
};
const FormSchema = z.object({
  activity: z.union([z.nativeEnum(ActivityType), z.literal("")]).optional(),
  after: z.coerce.date(),
  before: z.coerce.date().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const Activities = () => {
  const [_, setLocation] = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const activity = (searchParams.get("activity") as ActivityType) ?? undefined;
  const after = searchParams.has("after")
    ? parseISO(searchParams.get("after") ?? "")
    : subDays(new Date(), 1);
  const before = searchParams.has("before")
    ? parseISO(searchParams.get("before") ?? "")
    : undefined;
  const { data: activities, error } = useActivitiesQuery(
    activity,
    before,
    after,
  );
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("timestamp");

  const sortKey = (activities: Activity) => [activities[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={6} />;
  let sortedActivities;
  if (axios.isAxiosError(error) && error.response?.status === 413) {
    rows = (
      <TableRow>
        <TableCell colSpan={6}>
          Too much data requested: Maximum is 2 days
        </TableCell>
      </TableRow>
    );
  } else if (activities !== undefined && activities.length > 0) {
    sortedActivities = activities.sort(sortByKey(sortKey, order));
    rows = sortedActivities.map((activity) => (
      <TableRow key={`${activity.activity}-${activity.timestamp.valueOf()}`}>
        <TableCell>{formatDateTime(activity.timestamp)}</TableCell>
        <TableCell>{activity.activity}</TableCell>
        <TableCell>{activity.firebaseUid}</TableCell>
        <TableCell>{activity.staffMemberEmail}</TableCell>
        <TableCell>
          <code>{JSON.stringify(activity.data)}</code>
        </TableCell>
      </TableRow>
    ));
  } else if (activities !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={6}>No activities found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: { activity: "", before: before ?? null, after },
  });

  const onSubmit = (data: ValidatedType) => {
    const params: any = {
      after: formatISO(data.after, { representation: "date" }),
    };
    if (data.activity) {
      params["activity"] = data.activity;
    }
    if (data.before) {
      params["before"] = formatISO(data.before, { representation: "date" });
    }
    const newSearchParams = new URLSearchParams(params as any);
    setLocation(`/activities/?${newSearchParams.toString()}`, {
      replace: true,
    });
    methods.reset(data);
  };

  return (
    <>
      <Title title="Activities" />
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid alignItems="center" container spacing={2}>
                <Grid size={{ xs: 12, sm: 3 }}>
                  <AutocompleteField
                    freeSolo={false}
                    fullWidth
                    label="Activity"
                    name="activity"
                    options={activityTypeList()}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                  <DateField fullWidth label="Before" name="before" />
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                  <DateField fullWidth label="After" name="after" required />
                </Grid>
                <Grid size={{ xs: 12, sm: 3 }}>
                  <SubmitButton fullWidth label="Filter" />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "timestamp"}
                    direction={order}
                    onClick={onSortClick("timestamp")}
                  >
                    Timestamp
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "activity"}
                    direction={order}
                    onClick={onSortClick("activity")}
                  >
                    Activity
                  </TableSortLabel>
                </TableCell>
                <TableCell>Firebase id</TableCell>
                <TableCell>Staff</TableCell>
                <TableCell>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Activities;
