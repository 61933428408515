import { alpha, styled } from "@mui/material/styles";

export const CalendarStyleContainer = styled("div")(({ theme }) => ({
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-event-text-color": theme.palette.common.black,
    "--fc-list-event-hover-bg-color": theme.palette.background.default,
    "--fc-page-bg-color & --fc-day-list": theme.palette.neutral[200],
    "--fc-today-bg-color": alpha(theme.palette.divider, 0.3),
  },
  "& .fc-daygrid-day-number": {
    cursor: "pointer",
  },
  "& .fc-event-title-container": {
    display: "flex",
    alignItems: "center",
  },
  "& .fc-event": {
    fontSize: theme.typography.subtitle1.fontSize,
    borderWidth: 0,
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    margin: 1,
  },
  "& .fc-event:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.3),
  },

  "& .fc-event-main": {
    cursor: "pointer",
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
    display: "flex",
    alignItems: "center",
    paddingLeft: 4,
    color: theme.palette.neutral[800],
    borderRadius: 1,
    overflow: "hidden",
  },
  "& .fc-event-main:hover": {
    backgroundColor: theme.palette.neutral[200],
  },
  "& .fc-popover": {
    zIndex: 1,
  },
  "& .fc-button": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.contrastText,
    boxShadow: "none !important",
  },
  "& .fc-button:hover": {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.contrastText,
  },
  "& .fc-button-primary:not(:disabled).fc-button-active": {
    backgroundColor: theme.palette.action.disabled,
    borderColor: theme.palette.primary.contrastText,
  },
  "& .fc-button:disabled": {
    backgroundColor: theme.palette.action.disabled,
    borderColor: theme.palette.primary.contrastText,
    opacity: 1,
  },
  " .fc-button.fc-button-primary:active": {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.contrastText,
  },
  "& .fc-button-active:selected": {
    borderColor: theme.palette.primary.contrastText,
  },

  "& .fc-toolbar-title": {
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.h6.fontSize,
  },
  "& .fc .fc-col-header-cell-cushion, .fc-list-day": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.overline.fontSize,
    fontWeight: theme.typography.overline.fontWeight,
    letterSpacing: theme.typography.overline.letterSpacing,
    lineHeight: theme.typography.overline.lineHeight,
    textTransform: theme.typography.overline.textTransform,
  },
  "& .fc-timegrid-slot": {
    height: "40px",
  },
  "& .fc-daygrid-event": {
    padding: 2,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  },
  "& .fc-timegrid-slot-label": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.body1.fontWeight,
  },
  "& .fc-event-title": {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    borderRadius: 2,
  },
  "& .fc-daygrid-block-event .fc-event-time": {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
  "& .fc-daygrid-event-dot": {
    display: "none",
  },
  "& .fc-daygrid-day-frame": {
    padding: "12px",
  },
  "& .fc-scrollgrid": {
    borderColor: "transparent",
  },
  "& .fc-scrollgrid td:last-of-type": {
    borderRightColor: "transparent",
  },
  '& .fc-scrollgrid-section.fc-scrollgrid-section-body td[role="presentation"]':
    {
      borderBottomColor: "transparent",
    },
  '& [role="row"]:last-of-type td': {
    borderBottomColor: "transparent",
  },
  '& th[role="presentation"]': {
    borderRightColor: "transparent",
  },
  "& .fc-list": {
    borderColor: "transparent",
  },
  "@media (prefers-color-scheme: dark)": {
    "& .fc-col-header-cell-cushion, .fc-list-day": {
      backgroundColor: "black",
    },
    "& .fc": {
      "--fc-page-bg-color": theme.palette.background.paper,
    },
    "& .fc-event-main": {
      color: theme.palette.neutral[200],
    },
    "& .fc-event-main:hover": {
      backgroundColor: alpha(theme.palette.neutral[600], 0.5),
    },
    "& .fc-button-primary": {
      border: "none",
    },
  },
}));
