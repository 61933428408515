import { zodDecimal } from "@curaleaf-international/components";
import * as z from "zod";

export enum AppointmentStatus {
  ABANDONED = "ABANDONED",
  CANCELLED = "CANCELLED",
  CLINICIAN_DNA = "CLINICIAN_DNA",
  CONFIRMED = "CONFIRMED",
  PATIENT_DNA = "PATIENT_DNA",
  PENDING = "PENDING",
}
export enum AppointmentType {
  CHECK_UP = "CHECK_UP",
  FOLLOW_UP = "FOLLOW_UP",
  INITIAL = "INITIAL",
}
const appointmentSchema = z.object({
  id: z.string().uuid(),
  appointmentFirebaseId: z.string().nullable(),
  bookedOn: z.coerce.date(),
  clinicianId: z.string().uuid(),
  clinicianName: z.string(),
  currentStateTimestamp: z.coerce.date(),
  endAt: z.coerce.date(),
  length: z.coerce.number().int().positive(),
  patientFirebaseUid: z.string(),
  patientId: z.string().uuid(),
  patientName: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    ),
  price: zodDecimal(),
  startAt: z.coerce.date(),
  status: z.nativeEnum(AppointmentStatus),
  type: z.nativeEnum(AppointmentType),
  woocommerceBookingNumber: z.number().nullable(),
});

export type Appointment = z.infer<typeof appointmentSchema>;

export const newAppointment = (data: unknown): Appointment =>
  appointmentSchema.parse(data);
