import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

import { PaymentAction, PaymentState } from "src/models/utils";

enum ProcessorType {
  LEGACY = "LEGACY",
  STRIPE = "STRIPE",
  VIVA = "VIVA",
}

const appointmentPaymentSchema = z.object({
  id: z.string().uuid(),
  action: z.nativeEnum(PaymentAction),
  amount: zodDecimal(),
  appointmentId: z.string().uuid(),
  patientId: z.string().uuid(),
  processor: z.nativeEnum(ProcessorType),
  state: z.nativeEnum(PaymentState),
  stateTimestamp: z.coerce.date(),
  stripeId: z.string().nullable(),
  vivaId: z.string().nullable(),
  xeroId: z.string().nullable(),
  xeroPaymentId: z.string().nullable(),
});

export type AppointmentPayment = z.infer<typeof appointmentPaymentSchema>;

export const newAppointmentPayment = (data: unknown): AppointmentPayment =>
  appointmentPaymentSchema.parse(data);
