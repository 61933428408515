import { ScrollToTop, NotFound } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

import PageWrapper from "src/components/PageWrapper";
import PrivateRoute from "src/components/PrivateRoute";
import ActivateProductGroup from "src/pages/ActivateProductGroup";
import Activities from "src/pages/Activities";
import AddClinicianUnavailability from "src/pages/AddClinicianUnavailability";
import AddPrescriberSignature from "src/pages/AddPrescriberSignature";
import AlterAppointmentPrice from "src/pages/AlterAppointmentPrice";
import Appointment from "src/pages/Appointment";
import AppointmentCredit from "src/pages/AppointmentCredit";
import AppointmentPayments from "src/pages/AppointmentPayments";
import Appointments from "src/pages/Appointments";
import ApproveTravelLetter from "src/pages/ApproveTravelLetter";
import AssignTravelLetter from "src/pages/AssignTravelLetter";
import BookAppointment from "src/pages/BookAppointment";
import CancelAppointment from "src/pages/CancelAppointment";
import CancelAppointmentCredit from "src/pages/CancelAppointmentCredit";
import CancelSubscription from "src/pages/CancelSubscription";
import CancelTravelLetter from "src/pages/CancelTravelLetter";
import ChangeSubscriptionBillingDay from "src/pages/ChangeSubscriptionBillingDay";
import Clinician from "src/pages/Clinician";
import Clinicians from "src/pages/Clinicians";
import CloseOutOfStockListing from "src/pages/CloseOutOfStockListing";
import CreateAndDestroyFP10 from "src/pages/CreateAndDestroyFP10";
import CreateAppointment from "src/pages/CreateAppointment";
import CreateAppointmentCredit from "src/pages/CreateAppointmentCredit";
import CreateClinician from "src/pages/CreateClinician";
import CreateOutOfStockListing from "src/pages/CreateOutOfStockListing";
import CreatePrescriber from "src/pages/CreatePrescriber";
import CreateProductGroup from "src/pages/CreateProductGroup";
import CreateSpecialityGroup from "src/pages/CreateSpecialityGroup";
import CreateStaffMember from "src/pages/CreateStaffMember";
import CreateSubscription from "src/pages/CreateSubscription";
import CreateTravelLetter from "src/pages/CreateTravelLetter";
import Dashboard from "src/pages/Dashboard";
import DisableStaff from "src/pages/DisableStaff";
import DiscontinueProductGroup from "src/pages/DiscontinueProductGroup";
import EditAppointment from "src/pages/EditAppointment";
import EditAppointmentCredit from "src/pages/EditAppointmentCredit";
import EditAppointmentLengths from "src/pages/EditAppointmentLengths";
import EditClinician from "src/pages/EditClinician";
import EditOutOfStockListing from "src/pages/EditOutOfStockListing";
import EditPrescriber from "src/pages/EditPrescriber";
import EditProductGroup from "src/pages/EditProductGroup";
import EditSpecialityGroup from "src/pages/EditSpecialityGroup";
import EditStaffFirebaseData from "src/pages/EditStaffFirebaseData";
import EditStaffMember from "src/pages/EditStaffMember";
import EditTravelLetter from "src/pages/EditTravelLetter";
import FP10 from "src/pages/FP10";
import FP10s from "src/pages/FP10s";
import GenerateTravelLetter from "src/pages/GenerateTravelLetter";
import HelpEndpointRoles from "src/pages/HelpEndpointRoles";
import ImportAppointmentPayment from "src/pages/ImportAppointmentPayment";
import Insecure from "src/pages/Insecure";
import Login from "src/pages/Login";
import OutOfStockListing from "src/pages/OutOfStockListing";
import OutOfStockListings from "src/pages/OutOfStockListings";
import OverrideSubscriptionNoticePeriod from "src/pages/OverrideSubscriptionNoticePeriod";
import Patient from "src/pages/Patient";
import ResolveEmailIssue from "src/pages/Patient/ResolveEmailIssue";
import Patients from "src/pages/Patients";
import Prescriber from "src/pages/Prescriber";
import Prescribers from "src/pages/Prescribers";
import ProductGroup from "src/pages/ProductGroup";
import ProductGroups from "src/pages/ProductGroups";
import ReleaseAppointment from "src/pages/ReleaseAppointment";
import RemoveAssignmentFromTravelLetter from "src/pages/RemoveAssignmentFromTravelLetter";
import Reports from "src/pages/Reports";
import SpecialityGroup from "src/pages/SpecialityGroup";
import SpecialityGroups from "src/pages/SpecialityGroups";
import Staff from "src/pages/Staff";
import StaffMember from "src/pages/StaffMember";
import Subscription from "src/pages/Subscription";
import Subscriptions from "src/pages/Subscriptions";
import TravelLetter from "src/pages/TravelLetter";
import TravelLetters from "src/pages/TravelLetters";
import UpdateClinicianAvailability from "src/pages/UpdateClinicianAvailability";
import UploadTravelLetter from "src/pages/UploadTravelLetter";

const ThrowError = () => {
  throw new Error("Test Error");
};

const uuidRegex =
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

const Router = () => (
  <PageWrapper>
    <ScrollToTop />
    <Switch>
      <PrivateRoute path="/private/">Test</PrivateRoute>
      <PrivateRoute path="/">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/activities/">
        <Activities />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`/appointment-credits/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <AppointmentCredit appointmentCreditId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`/appointment-credits/(?<id>${uuidRegex})/cancel/$`)}
      >
        {(params: any) => (
          <CancelAppointmentCredit appointmentCreditId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`/appointment-credits/(?<id>${uuidRegex})/edit/$`)}
      >
        {(params: any) => (
          <EditAppointmentCredit appointmentCreditId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`/appointment-lengths/(?<id>${uuidRegex})/edit/$`)}
      >
        {(params: any) => <EditAppointmentLengths clinicianId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/appointment-payments/">
        <AppointmentPayments />
      </PrivateRoute>
      <PrivateRoute path="/appointments/">
        <Appointments />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/appointments/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <Appointment appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/appointments/(?<id>${uuidRegex})/alter-price/$`)}
      >
        {(params: any) => <AlterAppointmentPrice appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/appointments/(?<id>${uuidRegex})/book/$`)}>
        {(params: any) => <BookAppointment appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/appointments/(?<id>${uuidRegex})/edit/$`)}>
        {(params: any) => <EditAppointment appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/appointments/(?<id>${uuidRegex})/cancel/$`)}
      >
        {(params: any) => <CancelAppointment appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/appointments/(?<id>${uuidRegex})/import-payment/$`)}
      >
        {(params: any) => (
          <ImportAppointmentPayment appointmentId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/appointments/(?<id>${uuidRegex})/release/$`)}
      >
        {(params: any) => <ReleaseAppointment appointmentId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/clinicians/">
        <Clinicians />
      </PrivateRoute>
      <PrivateRoute path="/clinicians/new/">
        <CreateClinician />
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/clinician-availability/(?<id>${uuidRegex})/edit/$`)}
      >
        {(params: any) => (
          <UpdateClinicianAvailability clinicianId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/clinician-unavailability/(?<id>${uuidRegex})/new/$`)}
      >
        {(params: any) => (
          <AddClinicianUnavailability clinicianId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/clinicians/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <Clinician clinicianId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/clinicians/(?<id>${uuidRegex})/edit/$`)}>
        {(params: any) => <EditClinician clinicianId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/fp10s/">
        <FP10s />
      </PrivateRoute>
      <PrivateRoute path="/fp10s/destroy/">
        <CreateAndDestroyFP10 />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/fp10s/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <FP10 fp10Id={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/patients/">
        <Patients />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/patients/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <Patient patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/patients/(?<id>${uuidRegex})/new-appointment/$`)}
      >
        {(params: any) => <CreateAppointment patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/patients/(?<id>${uuidRegex})/new-appointment-credit/$`)}
      >
        {(params: any) => <CreateAppointmentCredit patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/patients/(?<id>${uuidRegex})/new-subscription/$`)}
      >
        {(params: any) => <CreateSubscription patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/patients/(?<id>${uuidRegex})/new-travel-letter/$`)}
      >
        {(params: any) => <CreateTravelLetter patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/patients/(?<id>${uuidRegex})/resolve-email-issue/$`)}
      >
        {(params: any) => <ResolveEmailIssue patientId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/prescribers/">
        <Prescribers />
      </PrivateRoute>
      <PrivateRoute path="/prescribers/new/">
        <CreatePrescriber />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/prescribers/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <Prescriber prescriberId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/prescribers/(?<id>${uuidRegex})/edit/$`)}>
        {(params: any) => <EditPrescriber prescriberId={params.id} />}
      </PrivateRoute>

      <PrivateRoute path="/product-groups/">
        <ProductGroups />
      </PrivateRoute>
      <PrivateRoute path="/product-groups/new/">
        <CreateProductGroup />
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/product-groups/(?<id>[a-zA-Z0-9]+)/$")}>
        {(params: any) => <ProductGroup productGroupId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/product-groups/(?<id>[a-zA-Z0-9]+)/edit/$")}
      >
        {(params: any) => <EditProductGroup productGroupId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/product-groups/(?<id>[a-zA-Z0-9]+)/activate/$")}
      >
        {(params: any) => <ActivateProductGroup productGroupId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/product-groups/(?<id>[a-zA-Z0-9]+)/deactivate/$")}
      >
        {(params: any) => (
          <DiscontinueProductGroup productGroupId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/out-of-stock-listings/">
        <OutOfStockListings />
      </PrivateRoute>
      <PrivateRoute path="/out-of-stock-listings/new/">
        <CreateOutOfStockListing />
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/out-of-stock-listings/(?<id>[a-zA-Z0-9]+)/$")}
      >
        {(params: any) => <OutOfStockListing listingId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/out-of-stock-listings/(?<id>[a-zA-Z0-9]+)/edit/$")}
      >
        {(params: any) => <EditOutOfStockListing listingId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp("^/out-of-stock-listings/(?<id>[a-zA-Z0-9]+)/close/$")}
      >
        {(params: any) => <CloseOutOfStockListing listingId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/reports/">
        <Reports />
      </PrivateRoute>
      <PrivateRoute path="/speciality-groups/">
        <SpecialityGroups />
      </PrivateRoute>
      <PrivateRoute path="/speciality-groups/new/">
        <CreateSpecialityGroup />
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/speciality-groups/(?<id>\\d+)/edit/$")}>
        {(params: any) => (
          <EditSpecialityGroup specialityGroupId={parseInt(params.id)} />
        )}
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/speciality-groups/(?<id>\\d+)/$")}>
        {(params: any) => (
          <SpecialityGroup specialityGroupId={parseInt(params.id)} />
        )}
      </PrivateRoute>

      <PrivateRoute path="/staff/">
        <Staff />
      </PrivateRoute>
      <PrivateRoute path="/staff/new/">
        <CreateStaffMember />
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/staff/(?<id>\\d+)/$")}>
        {(params: any) => <StaffMember staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/staff/(?<id>\\d+)/edit/roles/$")}>
        {(params: any) => <EditStaffMember staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/staff/(?<id>\\d+)/edit/firebase/$")}>
        {(params: any) => (
          <EditStaffFirebaseData staffId={parseInt(params.id)} />
        )}
      </PrivateRoute>
      <PrivateRoute path={RegExp("^/staff/(?<id>\\d+)/disabled/$")}>
        {(params: any) => <DisableStaff staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path="/help/endpoint-roles/">
        <HelpEndpointRoles />
      </PrivateRoute>
      <PrivateRoute path="/subscriptions/">
        <Subscriptions />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/subscriptions/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <Subscription subscriptionId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/subscriptions/(?<id>${uuidRegex})/cancel/$`)}
      >
        {(params: any) => <CancelSubscription subscriptionId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(
          `^/subscriptions/(?<id>${uuidRegex})/change-billing-day/$`,
        )}
      >
        {(params: any) => (
          <ChangeSubscriptionBillingDay subscriptionId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(
          `^/subscriptions/(?<id>${uuidRegex})/override-notice-period/$`,
        )}
      >
        {(params: any) => (
          <OverrideSubscriptionNoticePeriod subscriptionId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/travel-letters/">
        <TravelLetters />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/$`)}>
        {(params: any) => <TravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/assign/$`)}
      >
        {(params: any) => <AssignTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/approve/$`)}
      >
        {(params: any) => <ApproveTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/cancel/$`)}
      >
        {(params: any) => <CancelTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/edit/$`)}
      >
        {(params: any) => <EditTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/generate/$`)}
      >
        {(params: any) => <GenerateTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(
          `^/travel-letters/(?<id>${uuidRegex})/remove-assignment/$`,
        )}
      >
        {(params: any) => (
          <RemoveAssignmentFromTravelLetter travelLetterId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/travel-letters/(?<id>${uuidRegex})/upload/$`)}
      >
        {(params: any) => <UploadTravelLetter travelLetterId={params.id} />}
      </PrivateRoute>
      <Route path={RegExp(`^/prescribers/(?<id>${uuidRegex})/add-signature/$`)}>
        {(params: any) => <AddPrescriberSignature prescriberId={params.id} />}
      </Route>
      <Route path="/insecure/">
        <Insecure />
      </Route>
      <Route path="/login/">
        <Login />
      </Route>
      <Route path="/control/fe-error/">
        <ThrowError />
      </Route>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </PageWrapper>
);

export default Router;
