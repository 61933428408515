import {
  SubmitButton,
  FormLinks,
  AutocompleteField,
  TextField,
  CircularLoader,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { Role } from "src/models";
import { useAllStaffQuery } from "src/queries";

const FormSchema = z.object({
  reason: z.string(),
  serial: z
    .string()
    .min(11)
    .max(11)
    .refine((value) => {
      return value !== undefined && value.match(/^[0-9]{11}$/) !== null;
    }, "Invalid serial"),
  witnessId: z.string(),
});
type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  onSubmit: (data: ValidatedType) => Promise<any>;
  initialValues: FormType;
  clearOnSubmit: boolean;
}

const destructionReasons = [
  { label: "Spoiled", value: "Spoiled" },
  { label: "Expired", value: "Expired" },
];

const DestroyFP10Form = ({
  clearOnSubmit,
  initialValues,
  onSubmit,
}: IProps) => {
  const { data: staff } = useAllStaffQuery();

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  if (staff === undefined) {
    return <CircularLoader />;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(() => {
          onSubmit(methods.getValues());
          if (clearOnSubmit) {
            methods.reset();
          }
        })}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid size={12}>
                <TextField fullWidth required label="Serial" name="serial" />
              </Grid>
              <Grid size={12}>
                <AutocompleteField
                  fullWidth
                  required
                  label="Witness"
                  name="witnessId"
                  options={Object.values(staff)
                    .filter((s) => s.roles.includes(Role.CUSTOMER_SERVICE_LEAD))
                    .map((s) => ({
                      label: s.email,
                      value: s.id.toString(),
                    }))}
                />
              </Grid>
              <Grid size={12}>
                <SelectField
                  fullWidth
                  required
                  label="Reason"
                  name="reason"
                  options={destructionReasons}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Mark as destroyed" />
            <FormLinks links={[{ label: "Back", to: "/fp10s/" }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default DestroyFP10Form;
