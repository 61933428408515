import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Subscription } from "src/models";
import PatientSubscriptionRow from "src/pages/Patient/PatientSubscriptionsRow";
import { usePatientSubscriptionsQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientSubscriptions = ({ patientId }: IProps) => {
  const { data: subscriptions } = usePatientSubscriptionsQuery(patientId);

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions === undefined ? (
              <SkeletonRow cols={5} />
            ) : subscriptions.length === 0 ? (
              <TableRow>
                <TableCell>No subscriptions found.</TableCell>
              </TableRow>
            ) : (
              subscriptions.map((subscription: Subscription) => (
                <PatientSubscriptionRow
                  key={subscription.id}
                  subscription={subscription}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientSubscriptions;
