import { SkeletonRow } from "@curaleaf-international/components";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Link } from "wouter";

import EmailRow from "src/components/EmailRow";
import { Email } from "src/models";
import { usePatientQuery, usePatientEmailsQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientEmails = ({ patientId }: IProps) => {
  const { data: patient } = usePatientQuery(patientId);
  const { data: patientEmails } = usePatientEmailsQuery(patientId);

  const issue = patientEmails?.issues.at(0);

  const issueContent =
    issue === undefined || issue.resolution !== null ? null : (
      <Alert
        severity="warning"
        sx={{ padding: 2, marginBottom: 2 }}
        action={
          <Button
            sx={{ marginRight: 2 }}
            color="warning"
            size="small"
            component={Link}
            to={`/patients/${patient?.id}/resolve-email-issue/`}
            variant="contained"
          >
            Resolve
          </Button>
        }
      >
        <Typography variant="body1">
          Email issue detected Email issue of type {issue.type} detected for{" "}
          {issue.email}.
        </Typography>
        <Typography variant="body2">
          Description: {issue.description}
        </Typography>
      </Alert>
    );

  return (
    <>
      {issueContent}
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sent</TableCell>
                <TableCell>Message Type</TableCell>
                <TableCell>Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patient === undefined || patientEmails === undefined ? (
                <SkeletonRow cols={3} />
              ) : patientEmails.emails.length === 0 ? (
                <TableRow>
                  <TableCell>No emails found.</TableCell>
                </TableRow>
              ) : (
                patientEmails.emails.map((email: Email) => (
                  <EmailRow key={`${email.id}${email.sent}`} email={email} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default PatientEmails;
