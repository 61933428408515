import { ToastContext, Value } from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import MUIDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";

import { AppointmentSlot, Patient } from "src/models";
import { useCreateAppointmentMutation } from "src/queries/appointments";

interface IProps {
  appointmentSlots: AppointmentSlot[];
  onClose: () => void;
  open: boolean;
  patient?: Patient;
}

const Dialog = ({ appointmentSlots, onClose, open, patient }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createAppointment, isPending } =
    useCreateAppointmentMutation();
  const [slotIndex, setSlotIndex] = useState(0);

  useEffect(() => setSlotIndex(0), [appointmentSlots, setSlotIndex]);

  const selectedSlot = appointmentSlots[slotIndex];

  const onConfirm = async () => {
    try {
      const appointmentId = await createAppointment({
        appointmentType: selectedSlot.appointmentType,
        clinicianId: selectedSlot.clinicianId,
        patientId: patient!.id,
        start: selectedSlot.start,
      });
      setLocation(`/appointments/${appointmentId}/book/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "NOT_VALID"
      ) {
        addToast("Invalid appointment.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "SLOT_NOT_AVAILABLE"
      ) {
        addToast("Appointment slot not available.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "OVERLAPS_EXISTING_APPOINTMENT"
      ) {
        addToast("Overlaps existing appointment.", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <MUIDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Book Appointment</DialogTitle>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Clinician</TableCell>
              {appointmentSlots.length > 1 ? (
                <TableCell>
                  <Select
                    fullWidth
                    onChange={(event: SelectChangeEvent<string>) =>
                      setSlotIndex(parseInt(event.target.value))
                    }
                    size="small"
                    value={slotIndex.toString()}
                  >
                    {appointmentSlots.map((slot, index) => (
                      <MenuItem key={index} value={index.toString()}>
                        {slot.clinicianName}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              ) : (
                <TableCell>
                  <Value text={selectedSlot?.clinicianName} />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>
                <Value text={patient?.name} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Appointment Type</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                <Value
                  text={selectedSlot?.appointmentType
                    .toLowerCase()
                    .replace("_", "-")}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>
                <Value date={selectedSlot?.start} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>
                <Value time={selectedSlot?.start} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell>
                <Value currency={selectedSlot?.price} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          disabled={patient === undefined || selectedSlot === undefined}
          onClick={onConfirm}
          loading={isPending}
          variant="contained"
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
