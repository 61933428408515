import { SelectField } from "@curaleaf-international/components";

import { AppointmentType } from "src/models";

interface IProps {
  fullWidth?: boolean;
  label?: string;
  name: string;
  required?: boolean;
}

const AppointmentTypeSelect = ({
  fullWidth,
  label,
  name,
  required,
}: IProps) => (
  <SelectField
    fullWidth={fullWidth}
    label={label ?? "Appointment Type"}
    name={name}
    options={Object.values(AppointmentType).map((type) => ({
      label:
        type[0].toUpperCase() + type.slice(1).toLowerCase().replace("_", "-"),
      value: type,
    }))}
    required={required}
  />
);

export default AppointmentTypeSelect;
