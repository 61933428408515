import {
  formatCurrency,
  formatDateTime,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { SubscriptionPayment } from "src/models";

interface IProps {
  payment: SubscriptionPayment;
}

const SubscriptionPaymentRow = ({ payment }: IProps) => {
  return (
    <>
      <TableRow>
        <TableCell>{payment.id}</TableCell>
        <TableCell>{formatDateTime(payment.timestamp)}</TableCell>
        <TableCell>{payment.action}</TableCell>
        <TableCell>{payment.state}</TableCell>
        <TableCell>{formatCurrency(payment.amount)}</TableCell>
        <TableCell>
          {payment.staffId ? (
            <Link component={WLink} href={`/staff/${payment.staffId}/`}>
              {payment.staffEmail}
            </Link>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>{payment.reason ?? ""}</TableCell>
      </TableRow>
    </>
  );
};

export default SubscriptionPaymentRow;
