import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import SubscriptionDetails from "src/pages/Subscription/SubscriptionDetails";
import SubscriptionStateHistory from "src/pages/Subscription/SubscriptionHistory";
import SubscriptionPayments from "src/pages/Subscription/SubscriptionPayments";
import {
  usePatientQuery,
  useSubscriptionPaymentsQuery,
  useSubscriptionQuery,
} from "src/queries";

type TabState = "details" | "history" | "payments";

interface IProps {
  subscriptionId: string;
}

const Subscription = ({ subscriptionId }: IProps) => {
  const { data: subscription } = useSubscriptionQuery(subscriptionId);
  const { data: patient } = usePatientQuery(subscription?.patientId);
  const { data: paymentsData } = useSubscriptionPaymentsQuery(subscriptionId);
  const [tabState, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isPaidSubscription =
    subscription?.type === "CURA_CL" || subscription?.type === "CURA_CLI";

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/subscriptions/${subscriptionId}/change-billing-day/`}
          disabled={subscription?.status !== "ACTIVE" || !isPaidSubscription}
        >
          Change billing day
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/subscriptions/${subscriptionId}/cancel/`}
          disabled={subscription?.status === "CANCELLED"}
        >
          Cancel
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/subscriptions/${subscriptionId}/override-notice-period/`}
          disabled={
            subscription?.status !== "CANCELLED" ||
            !paymentsData?.nextPaymentDate ||
            !isPaidSubscription
          }
        >
          Override notice period
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={`Subscription - ${patient?.name ?? ""}`}
      />
      <TabContext value={tabState}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Details" value="details" />
          <Tab label="History" value="history" />
          <Tab label="Payments" value="payments" />
        </TabList>
        <TabPanel value="details">
          {subscription ? (
            <SubscriptionDetails subscription={subscription} />
          ) : (
            "Subscription not found"
          )}
        </TabPanel>
        <TabPanel value="history">
          <SubscriptionStateHistory subscriptionId={subscriptionId} />
        </TabPanel>
        <TabPanel value="payments">
          <SubscriptionPayments subscriptionId={subscriptionId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Subscription;
