import { Value } from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import DeactivateUnavailability from "src/components/DeactivateUnavailability";
import { ClinicianUnavailability } from "src/models/clinicianUnavailability";

interface IProps {
  clinicianUnavailability: ClinicianUnavailability[] | undefined;
}
const UnavailabilityTable = ({ clinicianUnavailability }: IProps) => {
  const [selectedUnavailability, setSelectedUnavailability] =
    useState<ClinicianUnavailability | null>(null);

  return (
    <>
      {selectedUnavailability !== null ? (
        <DeactivateUnavailability
          open={selectedUnavailability !== null}
          unavailability={selectedUnavailability}
          onClose={() => setSelectedUnavailability(null)}
        />
      ) : null}
      <Card>
        <CardHeader title="Unavailability" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell colSpan={1} />
              </TableRow>
            </TableHead>
            <TableBody>
              {clinicianUnavailability?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>No unavailability found.</TableCell>
                </TableRow>
              ) : (
                clinicianUnavailability?.map((unavailability, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Value dateTime={unavailability.startAt} />
                    </TableCell>
                    <TableCell>
                      <Value dateTime={unavailability.endAt} />
                    </TableCell>
                    <TableCell>
                      <Link
                        component={WLink}
                        to={`/staff/${unavailability.createdBy}/`}
                      >
                        <Value text={unavailability.createdByEmail} />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Value text={unavailability.reason} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setSelectedUnavailability(unavailability)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};
export default UnavailabilityTable;
