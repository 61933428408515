import {
  formatCurrency,
  formatDateTime,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { AppointmentPayment, PaymentAction } from "src/models";

interface IProps {
  payment: AppointmentPayment;
}

const PaymentRow = ({ payment }: IProps) => {
  return (
    <TableRow>
      <TableCell>{payment.id}</TableCell>
      <TableCell>
        {payment.state}
        <br />
        {formatDateTime(payment.stateTimestamp)}
      </TableCell>
      <TableCell>{formatCurrency(payment.amount)}</TableCell>
      <TableCell>
        <Link component={WLink} to={`/appointments/${payment.appointmentId}/`}>
          {payment.appointmentId}
        </Link>
      </TableCell>
      <TableCell>
        {payment.processor}
        <br />
        {payment.stripeId ?? payment.vivaId}
      </TableCell>
      <TableCell>
        <Link
          href={
            payment.action === PaymentAction.SALE
              ? `https://go.xero.com/app/!y1hh!/invoicing/view/${payment.xeroId}`
              : `https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=${payment.xeroId}`
          }
          target="_blank"
        >
          {payment.xeroId}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          href={`https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=${payment.xeroPaymentId}`}
          target="_blank"
        >
          {payment.xeroPaymentId}
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default PaymentRow;
