import {
  SkeletonRow,
  formatDateTime,
  sortByKey,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AppointmentCredit } from "src/models";
import { usePatientsAppointmentCreditsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "activeFrom"
  | "activeTo"
  | "state"
  | "subscriptionId";

interface IProps {
  patientId: string;
}

const AppointmentCredits = ({ patientId }: IProps) => {
  const { data: credits } = usePatientsAppointmentCreditsQuery(patientId);
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("state");

  const sortKey = (credits: AppointmentCredit) => [credits[orderBy]];

  let rows;
  if (credits === undefined) {
    rows = <SkeletonRow cols={8} />;
  } else if (credits.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={8}>No appointment credits.</TableCell>
      </TableRow>
    );
  } else {
    rows = credits
      .sort(sortByKey(sortKey, order))
      .map((credit: AppointmentCredit, index) => (
        <TableRow key={index}>
          <TableCell>
            <Link component={WLink} to={`/appointment-credits/${credit.id}/`}>
              {credit.id}
            </Link>
          </TableCell>
          <TableCell>
            {credit.appointmentId !== null ? (
              <Link
                component={WLink}
                to={`/appointments/${credit.appointmentId}/`}
              >
                {credit.appointmentId}
              </Link>
            ) : null}
          </TableCell>
          <TableCell>{credit.state}</TableCell>
          <TableCell>{formatDateTime(credit.activeFrom)}</TableCell>
          <TableCell>{formatDateTime(credit.activeTo)}</TableCell>
          <TableCell>
            {credit.subscriptionId ? (
              <Link
                component={WLink}
                href={`/subscriptions/${credit.subscriptionId}/`}
              >
                {credit.subscriptionId}
              </Link>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell>{credit.appointmentType}</TableCell>
          <TableCell>{credit.currentHistoryReason}</TableCell>
        </TableRow>
      ));
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Appointment Id</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "state"}
                  direction={order}
                  onClick={onSortClick("state")}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "activeFrom"}
                  direction={order}
                  onClick={onSortClick("activeFrom")}
                >
                  Active from
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "activeTo"}
                  direction={order}
                  onClick={onSortClick("activeTo")}
                >
                  Active to
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "subscriptionId"}
                  direction={order}
                  onClick={onSortClick("subscriptionId")}
                >
                  Subscription
                </TableSortLabel>
              </TableCell>
              <TableCell>Appointment Type</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AppointmentCredits;
