import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

import { PaymentAction, PaymentState } from "src/models/utils";

const subscriptionPaymentSchema = z.object({
  id: z.string().uuid(),
  action: z.nativeEnum(PaymentAction),
  amount: zodDecimal(),
  patientId: z.string().uuid(),
  reason: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  state: z.nativeEnum(PaymentState),
  stripeId: z.string(),
  subscriptionId: z.string().uuid(),
  timestamp: z.coerce.date(),
  xeroId: z.string().nullable(),
});

export type SubscriptionPayment = z.infer<typeof subscriptionPaymentSchema>;

const subscriptionPaymentsDataSchema = z.object({
  payments: z.array(subscriptionPaymentSchema),
  nextPaymentDate: z.coerce.date().nullable(),
});

export type SubscriptionPaymentsData = z.infer<
  typeof subscriptionPaymentsDataSchema
>;
export const newSubscriptionPaymentsData = (
  data: unknown,
): SubscriptionPaymentsData => subscriptionPaymentsDataSchema.parse(data);
