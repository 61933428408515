import axios from "axios";

import {
  AppointmentCreditHistory,
  newAppointmentCreditHistory,
} from "src/models/appointmentCreditHistory";
import { useQuery } from "src/query";

export const useAppointmentCreditHistoryQuery = (
  appointmentCreditId: string,
) => {
  return useQuery<AppointmentCreditHistory[]>({
    queryKey: ["appointmentCreditHistory", appointmentCreditId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-credits/${appointmentCreditId}/history/`,
      );
      return response.data.history.map((json: unknown) =>
        newAppointmentCreditHistory(json),
      );
    },
    enabled: appointmentCreditId !== undefined,
  });
};
