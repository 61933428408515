import {
  Title,
  ToastContext,
  formatDate,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useLocation, Link as WLink } from "wouter";

import {
  useOverrideSubscriptionNoticePeriodMutation,
  useGetSubscriptionHistoryQuery,
  usePatientQuery,
  useSubscriptionQuery,
} from "src/queries";

interface IProps {
  subscriptionId: string;
}

const OverrideSubscriptionNoticePeriod = ({ subscriptionId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: subscription } = useSubscriptionQuery(subscriptionId);
  const { mutateAsync: overrideNoticePeriod } =
    useOverrideSubscriptionNoticePeriodMutation(subscriptionId);
  const { data: patient } = usePatientQuery(subscription?.patientId);
  const { data: history } = useGetSubscriptionHistoryQuery(subscriptionId);

  const onClick = async () => {
    try {
      await overrideNoticePeriod();
      addToast("Subscription notice period cancelled", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  return (
    <>
      <Title title="Override subscription notice period" />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Typography marginBottom={2}>
            Overriding a subscription's notice period means that any remaining
            payments will not be taken.
          </Typography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${subscription?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{subscription?.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>
                    {subscription?.startDate
                      ? formatDate(subscription.startDate)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cancelled On</TableCell>
                  <TableCell>
                    {history?.[0].timestamp
                      ? formatDate(history?.[0].timestamp)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>{subscription?.type}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={subscription === undefined}
            onClick={onClick}
            variant="contained"
          >
            Confirm
          </Button>
          <Button component={WLink} to={`/subscriptions/${subscription?.id}/`}>
            Back
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default OverrideSubscriptionNoticePeriod;
