import {
  DateTimeField,
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import { startOfToday } from "date-fns";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { AppointmentType } from "src/models/appointment";
import {
  useAppointmentCreditQuery,
  useEditAppointmentCreditMutation,
  usePatientQuery,
} from "src/queries";

const FormSchema = z.object({
  activeTo: z.coerce.date(),
  appointmentType: z.nativeEnum(AppointmentType),
  reason: z.string().min(5, "A reason is required"),
});

type FormType = z.infer<typeof FormSchema>;

interface IProps {
  appointmentCreditId: string;
}

const EditAppointmentCredit = ({ appointmentCreditId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointmentCredit } =
    useAppointmentCreditQuery(appointmentCreditId);
  const { data: patient } = usePatientQuery(appointmentCredit?.patientId);
  const { mutateAsync: editAppointmentCredit } =
    useEditAppointmentCreditMutation(appointmentCreditId);

  const defaultValues = {
    appointmentType:
      appointmentCredit?.appointmentType ?? AppointmentType.FOLLOW_UP,
    activeTo: appointmentCredit?.activeTo ?? startOfToday(),
    reason: "",
  };

  const methods = useForm<FormType>({
    defaultValues: defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(
    () => methods.reset(defaultValues),
    [JSON.stringify(defaultValues)],
  );

  const onSubmit = async (data: FormType) => {
    try {
      await editAppointmentCredit({
        appointmentType: data.appointmentType,
        activeTo: data.activeTo,
        reason: data.reason,
      });
      addToast("Appointment Credit Edited", "success");
      setLocation(`/appointment-credits/${appointmentCreditId}/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "CREDIT_NOT_FOUND"
      ) {
        addToast("Could not find appointment credit", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "CREDIT_NOT_ACTIVE"
      ) {
        addToast("Cannot edit inactive credit", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Edit Appointment Credit"
        breadcrumbs={[
          {
            label: "Patients",
            to: `/patients/`,
          },
          {
            label: `${patient?.name}`,
            to: `/patients/${appointmentCredit?.patientId}/`,
          },
          {
            label: "Appointment Credits",
            to: `/patients/${appointmentCredit?.patientId}/#appointmentCredits`,
          },
          {
            label: "Appointment Credit",
            to: `/appointment-credits/${appointmentCreditId}/`,
          },
          { label: "Edit Appointment Credit" },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container alignItems="center" sx={{ marginBottom: 2 }}>
                {" "}
                <Grid size={{ xs: 12, sm: 2 }}>
                  <FormLabel>Appointment Type</FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 10 }}>
                  <SelectField
                    size="small"
                    options={Object.values(AppointmentType).map((type) => ({
                      value: type,
                    }))}
                    name="appointmentType"
                    label="Appointment Type"
                    fullWidth
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 2 }}>
                  <FormLabel>Active To</FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 10 }}>
                  <DateTimeField fullWidth name="activeTo" label="Active To" />
                </Grid>
              </Grid>
              <TextField
                fullWidth
                required
                label="Reason for Editing Appointment Credit"
                name="reason"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Edit Appointment Credit" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointment-credits/${appointmentCreditId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditAppointmentCredit;
