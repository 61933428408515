import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  Value,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import {
  useAppointmentCreditQuery,
  useCancelAppointmentCreditMutation,
  usePatientQuery,
} from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

const FormSchema = z.object({
  reason: z.string().min(5, "A reason is required"),
});
type FormType = z.infer<typeof FormSchema>;

interface IProps {
  appointmentCreditId: string;
}

const CancelAppointmentCredit = ({ appointmentCreditId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointmentCredit } =
    useAppointmentCreditQuery(appointmentCreditId);
  const { data: patient } = usePatientQuery(appointmentCredit?.patientId);
  const { mutateAsync: cancelAppointmentCredit } =
    useCancelAppointmentCreditMutation(appointmentCreditId);

  const methods = useForm({
    defaultValues: {
      reason: "",
    },
    resolver: zodResolver(FormSchema),
  });
  const onSubmit = async (data: FormType) => {
    try {
      await cancelAppointmentCredit({
        reason: data.reason,
      });
      addToast("Appointment Credit Cancelled", "success");
      setLocation(`/appointment-credits/${appointmentCreditId}/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "CREDIT_NOT_FOUND"
      ) {
        addToast("Could not find appointment credit", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "CREDIT_NOT_ACTIVE"
      ) {
        addToast("Cannot cancel inactive credit", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Cancel Appointment Credit"
        breadcrumbs={[
          {
            label: "Patients",
            to: `/patients/`,
          },
          {
            label: `${patient?.name}`,
            to: `/patients/${appointmentCredit?.patientId}/`,
          },
          {
            label: "Appointment Credits",
            to: `/patients/${appointmentCredit?.patientId}/#appointmentCredits`,
          },
          {
            label: "Appointment Credit",
            to: `/appointment-credits/${appointmentCreditId}/`,
          },
          { label: "Cancel Appointment Credit" },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TableContainer sx={{ marginBottom: 2 }}>
                <Table>
                  <TableRow>
                    <TableCell>Appointment Type</TableCell>
                    <TableCell>
                      <Value
                        text={convertEnumValueToReadableString(
                          appointmentCredit?.appointmentType ?? "",
                          "-",
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Active From</TableCell>
                    <TableCell>
                      <Value dateTime={appointmentCredit?.activeFrom} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Active To</TableCell>
                    <TableCell>
                      <Value dateTime={appointmentCredit?.activeTo} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Reason for Credit</TableCell>
                    <TableCell>
                      <Value text={appointmentCredit?.currentHistoryReason} />
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
              <TextField
                fullWidth
                label="Reason for Cancelling Credit"
                name="reason"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Cancel Credit" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointment-credits/${appointmentCreditId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CancelAppointmentCredit;
