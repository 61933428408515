import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";
import { Decimal } from "decimal.js";

import {
  Appointment,
  AppointmentHistory,
  AppointmentStatus,
  AppointmentType,
  newAppointment,
  newAppointmentHistory,
} from "src/models";
import { PaginatedResult, PaginationSettings } from "src/pagination";
import { useMutation, useQuery } from "src/query";

export const useAppointmentsQuery = (
  after: Date,
  before: Date,
  clinician?: string | null,
  appointmentStatus?: AppointmentStatus | null,
  appointmentType?: AppointmentType | null,
) =>
  useQuery<Appointment[]>({
    queryKey: [
      "appointments",
      after.toLocaleDateString(),
      before.toLocaleDateString(),
      clinician?.toString() ?? "undefined",
      appointmentStatus?.toString() ?? "undefined",
      appointmentType?.toString() ?? "undefined",
    ],
    queryFn: async () => {
      const params = {
        after: formatISO(after, { representation: "date" }),
        before: formatISO(before, { representation: "date" }),
        clinician,
        appointmentStatus,
        appointmentType,
      };
      const response = await axios.get("/v1/appointments/", { params });
      return response.data.appointments.map((json: unknown) =>
        newAppointment(json),
      );
    },
  });

export const useAppointmentQuery = (appointmentId: string) => {
  return useQuery<Appointment>({
    queryKey: ["appointments", appointmentId],
    queryFn: async () => {
      const response = await axios.get(`/v1/appointments/${appointmentId}/`);
      return newAppointment(response.data);
    },
    enabled: appointmentId !== undefined,
  });
};

export const usePatientAppointmentsQuery = (patientId: string | undefined) => {
  return useQuery<Appointment[]>({
    queryKey: ["appointments", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointments/patient/${patientId}/`,
      );
      return response.data.appointments.map((json: unknown) =>
        newAppointment(json),
      );
    },
    enabled: patientId !== undefined,
  });
};

export const useClinicianAppointmentsQuery = (
  clinicianId: string,
  upcoming: boolean,
  pagination?: Partial<PaginationSettings<Appointment>>,
) => {
  return useQuery<PaginatedResult<Appointment>>({
    queryKey: ["appointments", clinicianId, upcoming, pagination],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointments/clinician/${clinicianId}/`,
        {
          params: {
            ...(pagination ?? {}),
            upcoming,
          },
        },
      );
      return {
        rows: response.data.appointments.map((data: unknown) =>
          newAppointment(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
    enabled: clinicianId !== undefined,
    placeholderData: keepPreviousData,
  });
};

interface ICreateAppointment {
  appointmentType: AppointmentType;
  clinicianId: string;
  patientId: string;
  start: Date;
}

export const useCreateAppointmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICreateAppointment) => {
      const response = await axios.post("/v1/appointments/", {
        appointmentType: data.appointmentType,
        clinicianId: data.clinicianId,
        patientId: data.patientId,
        start: formatISO(data.start),
      });
      return response.data.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

interface IAlterAppointmentPrice {
  price: Decimal;
  reason: string;
}

export const useAlterAppointmentPriceMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IAlterAppointmentPrice) =>
      await axios.put(`/v1/appointments/${appointmentId}/price/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentPayments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory"],
      });
    },
  });
};

export const useReleaseAppointmentMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await axios.put(`/v1/appointments/${appointmentId}/release/`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory"],
      });
    },
  });
};

interface IConfirmAppointment {
  creditId: string | null;
  method: string;
  reason: string;
}

export const useConfirmAppointmentMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IConfirmAppointment) => {
      await axios.put(`/v1/appointments/${appointmentId}/confirm/`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments", appointmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory"],
      });
    },
  });
};

interface ICancelAppointment {
  refund: boolean;
  reason: string;
}

export const useCancelAppointmentMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICancelAppointment) =>
      await axios.put(`/v1/appointments/${appointmentId}/cancel/`, {
        refund: data.refund,
        reason: data.reason,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments", appointmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentPayments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentCredits"],
      });
    },
  });
};

interface IEditAppointment {
  appointmentType: AppointmentType;
  clinicianId: string;
  patientId: string;
  reason: string;
  start: Date;
}

export const useEditAppointmentMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEditAppointment) =>
      await axios.put(`/v1/appointments/${appointmentId}/edit/`, {
        appointmentType: data.appointmentType,
        clinicianId: data.clinicianId,
        patientId: data.patientId,
        reason: data.reason,
        start: formatISO(data.start),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointments", appointmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentPayments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory", appointmentId],
      });
    },
  });
};

export const useAppointmentHistoryQuery = (appointmentId: string) => {
  return useQuery<AppointmentHistory[]>({
    queryKey: ["appointmentHistory", appointmentId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointments/${appointmentId}/history/`,
      );
      return response.data.history.map((json: unknown) =>
        newAppointmentHistory(json),
      );
    },
    enabled: appointmentId !== undefined,
  });
};
