import {
  AutocompleteField,
  DateField,
  SubmitButton,
  formatDateTime,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { subDays } from "date-fns";
import { ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { Activity } from "src/models";
import { ActivityType, activityTypeList } from "src/pages/Activities";
import { useActivitiesQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "activity" | "timestamp";

const FormSchema = z.object({
  activity: z.union([z.nativeEnum(ActivityType), z.literal("")]).nullable(),
  after: z.coerce.date(),
  before: z.coerce.date().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  staffId: number;
}

const StaffActivities = ({ staffId }: IProps) => {
  const [activity, setActivity] = useState<ActivityType | null>(null);
  const [before, setBefore] = useState<Date | null>(null);
  const [after, setAfter] = useState<Date>(subDays(new Date(), 90));
  const { data: activities } = useActivitiesQuery(
    activity,
    before,
    after,
    staffId,
  );
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("timestamp");

  const sortKey = (activities: Activity) => [activities[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={3} />;
  let filteredSortedActivities;

  if (activities && activities.length > 0) {
    filteredSortedActivities = activities.sort(sortByKey(sortKey, order));

    rows = filteredSortedActivities.map((activity) => (
      <TableRow key={`${activity.activity}-${activity.timestamp}`}>
        <TableCell>{formatDateTime(activity.timestamp)}</TableCell>
        <TableCell>{activity.activity}</TableCell>
        <TableCell>{activity.firebaseUid}</TableCell>
        <TableCell>
          <code>{JSON.stringify(activity.data)}</code>
        </TableCell>
      </TableRow>
    ));
  } else if (activities !== null) {
    rows = (
      <TableRow>
        <TableCell colSpan={3}>No activities found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      activity: null,
      before: before ?? null,
      after: after ?? "",
    },
  });

  const onSubmit = (data: ValidatedType) => {
    const params: any = {
      activity: data.activity,
      before: data.before ?? null,
      after: data.after,
      staffId,
    };
    setBefore(params.before);
    setAfter(params.after);
    setActivity(params.activity);
    methods.reset(data);
  };

  return (
    <>
      <CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid alignItems="center" container spacing={2}>
              <Grid size={{ xs: 12, sm: 3 }}>
                <AutocompleteField
                  freeSolo={false}
                  fullWidth
                  label="Activity"
                  name="activity"
                  options={activityTypeList()}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <DateField fullWidth label="Before" name="before" />
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <DateField fullWidth label="After" name="after" />
              </Grid>
              <Grid size={{ xs: 12, sm: 3 }}>
                <SubmitButton fullWidth label="Filter" />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "timestamp"}
                  direction={order}
                  onClick={onSortClick("timestamp")}
                >
                  Timestamp
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "activity"}
                  direction={order}
                  onClick={onSortClick("activity")}
                >
                  Activity
                </TableSortLabel>
              </TableCell>
              <TableCell>Firebase ID</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StaffActivities;
