import { EventInput } from "@fullcalendar/core/index.js";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { addWeeks, subWeeks } from "date-fns";

import { CalendarStyleContainer } from "src/components/CalendarStyleContainer";

interface IProps {
  events: EventInput[];
  handleDatesSelect: (start: Date, end: Date) => void;
  handleEventClick: (id: string) => void;
}

const UpdateAvailabilityCalendar = ({
  events,
  handleDatesSelect,
  handleEventClick,
}: IProps) => {
  const currentDate = new Date();

  return (
    <CalendarStyleContainer>
      <FullCalendar
        allDaySlot={false}
        eventClick={(event) => handleEventClick(event.event.id)}
        events={events}
        eventTimeFormat={{
          timeStyle: "short",
        }}
        firstDay={1}
        headerToolbar={{
          left: "today",
          center: "title",
          right: "prev,next",
        }}
        height="65vh"
        initialView={screen.width > 600 ? "timeGridWeek" : "timeGridDay"}
        locale="en-IE"
        plugins={[interactionPlugin, timeGridPlugin]}
        select={(value) => handleDatesSelect(value.start, value.end)}
        selectable
        selectOverlap={false}
        slotLabelFormat={{
          timeStyle: "short",
        }}
        slotMinTime="07:00:00"
        slotMaxTime="23:00:00"
        validRange={{
          start: subWeeks(currentDate, 2),
          end: addWeeks(currentDate, 24),
        }}
      />
    </CalendarStyleContainer>
  );
};

export default UpdateAvailabilityCalendar;
