import * as z from "zod";

const clinicianUnavailabilitySchema = z.object({
  id: z.number().int().positive(),
  active: z.boolean(),
  clinicianId: z.string().uuid(),
  clinicianName: z.string(),
  created: z.coerce.date(),
  createdBy: z.coerce.number().int().positive(),
  createdByEmail: z.string().email(),
  endAt: z.coerce.date(),
  reason: z.string(),
  startAt: z.coerce.date(),
});

export type ClinicianUnavailability = z.infer<
  typeof clinicianUnavailabilitySchema
>;

export const newClinicianUnavailability = (
  data: unknown,
): ClinicianUnavailability => clinicianUnavailabilitySchema.parse(data);
