import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { isPast } from "date-fns";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AppointmentCreditState } from "src/models";
import CreditDetails from "src/pages/AppointmentCredit/CreditDetails";
import CreditHistory from "src/pages/AppointmentCredit/CreditHistory";
import { useAppointmentCreditQuery } from "src/queries/appointmentCredits";
import { usePatientQuery } from "src/queries/patients";

interface IProps {
  appointmentCreditId: string;
}

type TabState = "details" | "history";

const AppointmentCredit = ({ appointmentCreditId }: IProps) => {
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: appointmentCredit } =
    useAppointmentCreditQuery(appointmentCreditId);
  const { data: patient } = usePatientQuery(appointmentCredit?.patientId);
  const open = Boolean(anchorEl);
  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/appointment-credits/${appointmentCreditId}/cancel/`}
          disabled={
            appointmentCredit?.state !== AppointmentCreditState.ACTIVE ||
            isPast(appointmentCredit?.activeTo)
          }
        >
          Cancel Appointment Credit
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointment-credits/${appointmentCreditId}/edit/`}
          disabled={
            appointmentCredit?.state !== AppointmentCreditState.ACTIVE ||
            isPast(appointmentCredit?.activeTo)
          }
        >
          Edit Appointment Credit
        </MenuItem>
      </Menu>
      <Title
        title="Appointment Credit"
        breadcrumbs={[
          {
            label: "Patients",
            to: `/patients/`,
          },
          {
            label: `${patient?.name}`,
            to: `/patients/${appointmentCredit?.patientId}/`,
          },
          {
            label: "Appointment Credits",
            to: `/patients/${appointmentCredit?.patientId}/#appointmentCredits`,
          },
          { label: "Appointment Credit" },
        ]}
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList onChange={(_, value: string) => setTab(value as TabState)}>
            <Tab label="Details" value="details" />
            <Tab label="History" value="history" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <CreditDetails credit={appointmentCredit} />
        </TabPanel>
        <TabPanel value="history">
          <CreditHistory appointmentCreditId={appointmentCreditId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AppointmentCredit;
