import {
  CheckboxField,
  FormLinks,
  SubmitButton,
  Title,
  ToastContext,
  Value,
  AutocompleteField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { differenceInDays, differenceInHours } from "date-fns";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import {
  useAppointmentQuery,
  useCancelAppointmentMutation,
  useAppointmentsCreditsQuery,
} from "src/queries";

interface IProps {
  appointmentId: string;
}

const FormSchema = z.object({
  refund: z.boolean(),
  reason: z.string(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const cancellationOptions = [
  { value: "Clinician Unavailable" },
  { value: "Clinician Medical Issue" },
  { value: "Clinician Technical Issue" },
  { value: "Patient Unavailable" },
  { value: "Patient Medical Issue" },
  { value: "Patient Technical Issue" },
];

const CancelAppointment = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { data: appointmentCredits } =
    useAppointmentsCreditsQuery(appointmentId);
  const { mutateAsync: cancelAppointment } =
    useCancelAppointmentMutation(appointmentId);

  const initialValues = {
    refund:
      appointment !== undefined
        ? differenceInDays(appointment?.startAt, new Date()) >= 3
          ? true
          : false
        : false,
    reason: "",
  };

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    methods.reset(initialValues);
  }, [appointment]);

  const values = methods.watch();

  const freeAppointment = appointment?.price.isZero() ?? false;

  let submitLabel = "Cancel Appointment";
  if (!freeAppointment && values.refund) {
    submitLabel = "Cancel and Refund";
  } else if (!freeAppointment && !values.refund) {
    submitLabel = "Cancel Without Refund";
  }

  const onSubmit = async (data: ValidatedType) => {
    try {
      await cancelAppointment({
        refund: data.refund,
        reason: data.reason,
      });
      addToast("Appointment Cancelled", "success");
      setLocation(`/appointments/${appointmentId}/`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        addToast("Appointment not found", "error");
      }
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        addToast("Cannot cancel appointment in the past", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <Title
        title="Cancel Appointment"
        breadcrumbs={[
          { label: "Appointments", to: "/appointments/" },
          {
            label: `Appointment - ${appointment?.patientName}`,
            to: `/appointments/${appointmentId}/`,
          },
          { label: "Cancel Appointment" },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              {appointment &&
              !appointment.price.isZero() &&
              differenceInHours(appointment?.startAt, new Date()) >= 72 ? (
                <Alert severity="info" sx={{ marginBottom: 2 }}>
                  A refund is due as this appointment is 72 hours or more in the
                  future.
                </Alert>
              ) : appointment && !appointment.price.isZero() ? (
                <Alert severity="info" sx={{ marginBottom: 2 }}>
                  A refund is not automatically due as this appointment is less
                  than 72 hours in the future.
                </Alert>
              ) : null}
              <TableContainer sx={{ marginBottom: 2 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Patient</TableCell>
                      <TableCell>
                        <Value
                          link={{
                            label: `${appointment?.patientName}`,
                            to: `/patients/${appointment?.patientId}/`,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Clinician</TableCell>
                      <TableCell>
                        <Value text={appointment?.clinicianName} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Start</TableCell>
                      <TableCell>
                        <Value dateTime={appointment?.startAt} />{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>
                        <Value text={appointment?.type} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell>
                        <Value currency={appointment?.price} />
                      </TableCell>
                    </TableRow>
                    {!freeAppointment && appointmentCredits ? (
                      <TableRow>
                        <TableCell>Paid By</TableCell>
                        <TableCell>
                          <Value
                            text={
                              appointmentCredits.length > 0
                                ? "Appointment Credit"
                                : "Standard Payment"
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                spacing={freeAppointment ? 0 : 1}
                alignItems={"center"}
              >
                {!freeAppointment ? (
                  <Grid size={{ xs: 12, sm: 3 }}>
                    <CheckboxField
                      fullWidth
                      name="refund"
                      label={`Refund ${appointmentCredits && appointmentCredits?.length > 0 ? "Appointment Credit" : "Payment"}`}
                    />
                  </Grid>
                ) : null}
                <Grid size={{ xs: 12, sm: freeAppointment ? 12 : 9 }}>
                  <AutocompleteField
                    fullWidth
                    freeSolo
                    options={cancellationOptions}
                    name="reason"
                    label="Reason for Cancellation"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={appointment === undefined}
                label={submitLabel}
              />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointments/${appointment?.id}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default CancelAppointment;
