import { CircularLoader, Title } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import { isPast } from "date-fns";
import { useState } from "react";

import { ClinicianAvailability } from "src/models/clinicianAvailability";
import AddAvailabilityDialog from "src/pages/UpdateClinicianAvailability/AddAvailabilityDialog";
import EditAvailabilityDialog from "src/pages/UpdateClinicianAvailability/EditAvailabilityDialog";
import UpdateAvailabilityCalendar from "src/pages/UpdateClinicianAvailability/UpdateAvailabilityCalendar";
import { useClinicianQuery, useClinicianAvailabilityQuery } from "src/queries";
import { useCliniciansUnavailabilityQuery } from "src/queries/clinicianUnavailability";
import {
  getClinicianAvailabilityEvents,
  getClinicianUnavailabilityEvents,
} from "src/utils";

interface IProps {
  clinicianId: string;
}

const UpdateClinicianAvailability = ({ clinicianId }: IProps) => {
  const [selectedAvailability, setSelectedAvailability] = useState<
    ClinicianAvailability | undefined
  >(undefined);
  const [selectedTime, setSelectedTime] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: new Date(),
  });
  const [addAvailabilityDialogOpen, setAddAvailabilityDialogOpen] =
    useState(false);
  const [editAvailabilityDialogOpen, setEditAvailabilityDialogOpen] =
    useState(false);
  const { data: clinicianAvailability } =
    useClinicianAvailabilityQuery(clinicianId);
  const { data: clinicianUnavailability } =
    useCliniciansUnavailabilityQuery(clinicianId);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const theme = useTheme();

  const availability = getClinicianAvailabilityEvents(
    clinicianAvailability ?? [],
    theme,
  );
  const unavailability = getClinicianUnavailabilityEvents(
    clinicianUnavailability ?? [],
    theme,
  );

  const allEvents = availability?.concat(unavailability ?? []) ?? [];

  if (clinicianAvailability === undefined || clinician === undefined) {
    return <CircularLoader />;
  }

  const handleDatesSelect = (start: Date, end: Date) => {
    setSelectedTime({ start: start, end: end });
    setAddAvailabilityDialogOpen(true);
  };

  const handleEventClick = (availabilityId: string) => {
    const availability = clinicianAvailability.find(
      (availability) => availability.id.toString() === availabilityId,
    );
    if (availability && !isPast(availability.startAt)) {
      setSelectedAvailability(availability);
      setEditAvailabilityDialogOpen(true);
    }
  };

  return (
    <>
      <Title
        title={`Update Availability for ${clinician.name}`}
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician.name}`, to: `/clinicians/${clinicianId}/` },
          { label: `Update Availability for ${clinician.name}` },
        ]}
        actions={
          <Button
            variant="contained"
            onClick={() => setAddAvailabilityDialogOpen(true)}
          >
            Add Availability
          </Button>
        }
      />
      <Card>
        <CardContent>
          <UpdateAvailabilityCalendar
            events={allEvents}
            handleDatesSelect={(start: Date, end: Date) =>
              handleDatesSelect(start, end)
            }
            handleEventClick={(id: string) => handleEventClick(id)}
          />
        </CardContent>
      </Card>
      <AddAvailabilityDialog
        clinician={clinician}
        onClose={() => {
          setAddAvailabilityDialogOpen(false);
        }}
        open={addAvailabilityDialogOpen}
        selectedTime={selectedTime}
      />
      {selectedAvailability ? (
        <EditAvailabilityDialog
          clinician={clinician}
          onClose={() => {
            setSelectedAvailability(undefined);
            setEditAvailabilityDialogOpen(false);
          }}
          open={editAvailabilityDialogOpen}
          openEditRecurring={() => {
            setAddAvailabilityDialogOpen(true);
          }}
          availability={selectedAvailability}
        />
      ) : null}
    </>
  );
};
export default UpdateClinicianAvailability;
