import {
  formatCurrency,
  formatDate,
  formatDateTime,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { differenceInMinutes } from "date-fns";
import { Link as WLink } from "wouter";

import { Appointment } from "src/models";

interface IProps {
  appointment: Appointment;
}

const AppointmentDetails = ({ appointment }: IProps) => {
  return (
    <Card>
      <CardHeader title="Info" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>
                <Link
                  component={WLink}
                  to={`/patients/${appointment.patientId}/`}
                >
                  {appointment.patientName}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clinician</TableCell>
              <TableCell>
                <Link
                  component={WLink}
                  to={`/clinicians/${appointment.clinicianId}/`}
                >
                  {appointment.clinicianName}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>{formatDateTime(appointment.startAt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Length</TableCell>
              <TableCell>{appointment.length} minutes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Buffer</TableCell>
              <TableCell>
                {differenceInMinutes(appointment.endAt, appointment.startAt, {
                  roundingMethod: "ceil",
                }) - appointment.length}{" "}
                minutes
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>{appointment.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                {appointment.status} on{" "}
                {formatDateTime(appointment.currentStateTimestamp)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell>{formatCurrency(appointment.price)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>{formatDate(appointment.bookedOn)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>WooCommerce Booking Number</TableCell>
              <TableCell>{appointment.woocommerceBookingNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AppointmentDetails;
