import { Title } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useState } from "react";

import AppointmentCalendar from "src/components/AppointmentCalendar";
import { AppointmentSlot } from "src/models";
import Dialog from "src/pages/CreateAppointment/Dialog";
import { usePatientQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const CreateAppointment = ({ patientId }: IProps) => {
  const [slots, setSlots] = useState<AppointmentSlot[]>([]);
  const { data: patient } = usePatientQuery(patientId);

  return (
    <>
      <Dialog
        appointmentSlots={slots}
        onClose={() => setSlots([])}
        open={slots.length > 0}
        patient={patient}
      />
      <Title
        title={`Create Appointment for ${patient?.name}`}
        breadcrumbs={[
          { to: "/patients/", label: "Patients" },
          { label: patient?.name ?? "", to: `/patients/${patient?.id}/` },
          { label: `Create Appointment for ${patient?.name}` },
        ]}
      />
      <Card>
        <AppointmentCalendar
          onChoice={(slots: AppointmentSlot[]) => setSlots(slots)}
          patientId={patientId}
        />
      </Card>
    </>
  );
};
export default CreateAppointment;
