import {
  sortByKey,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import { Link } from "wouter";

import { SpecialityGroup } from "src/models";
import ClinicianRow from "src/pages/SpecialityGroups/SpecialityGroupRow";
import { useSpecialityGroupsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "specialityName";

const SpecialityGroupClinicians = () => {
  const [sortDirection, setSortDirection] = useState<Direction>("asc");
  const [sortBy, setSortBy] = useState<OrderableProperties>("specialityName");
  const { data: groups } = useSpecialityGroupsQuery();

  const sortKey = (group: SpecialityGroup) => [group[sortBy]];
  const sortedSpecialityGroups = groups?.sort(
    sortByKey(sortKey, sortDirection),
  );

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  return (
    <>
      <Title
        title="Speciality Groups"
        actions={
          <Button
            component={Link}
            to="/speciality-groups/new/"
            variant="contained"
          >
            Add Group
          </Button>
        }
      />
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "specialityName"}
                    direction={sortDirection}
                    onClick={onSortClick("specialityName")}
                  >
                    Speciality Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Number of Diagnoses</TableCell>
                <TableCell>Number of Clinicians</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSpecialityGroups === undefined ? (
                <SkeletonRow cols={3} />
              ) : sortedSpecialityGroups.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No Groups found.
                  </TableCell>
                </TableRow>
              ) : (
                sortedSpecialityGroups?.map((group) => (
                  <ClinicianRow key={group.id} specialityGroup={group} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SpecialityGroupClinicians;
