import { z } from "zod";

export enum SubscriptionStatus {
  ABANDONED = "ABANDONED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}

export enum SubscriptionType {
  CURA_CL = "CURA_CL",
  CURA_CLI = "CURA_CLI",
  FOUNDATION = "FOUNDATION",
  STAFF_AND_KIN = "STAFF_AND_KIN",
  VETERAN = "VETERAN",
}

const subscriptionSchema = z.object({
  id: z.string().uuid(),
  billingDay: z.number().int().positive().nullable(),
  patientId: z.string().uuid(),
  patientName: z.string(),
  startDate: z.coerce.date(),
  status: z.nativeEnum(SubscriptionStatus),
  stripeId: z.string().nullable(),
  type: z.nativeEnum(SubscriptionType),
});

export type Subscription = z.infer<typeof subscriptionSchema>;
export const newSubscription = (data: unknown): Subscription =>
  subscriptionSchema.parse(data);

const subscriptionHistorySchema = z.object({
  id: z.number().int().positive(),
  billingDay: z.number().int().positive().nullable(),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  subscriptionId: z.string().uuid(),
  reason: z.string().nullable(),
  status: z.nativeEnum(SubscriptionStatus),
  timestamp: z.coerce.date(),
  type: z.nativeEnum(SubscriptionType),
});

export type SubscriptionHistory = z.infer<typeof subscriptionHistorySchema>;
export const newSubscriptionHistory = (data: unknown): SubscriptionHistory =>
  subscriptionHistorySchema.parse(data);
