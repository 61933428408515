import { formatDateTime } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { useAppointmentCreditHistoryQuery } from "src/queries/appointmentCreditHistory";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  appointmentCreditId: string;
}

const CreditHistory = ({ appointmentCreditId }: IProps) => {
  const { data: creditHistory } =
    useAppointmentCreditHistoryQuery(appointmentCreditId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Timestamp</TableCell>

              <TableCell>Appointment Type</TableCell>
              <TableCell>Active To</TableCell>
              <TableCell>Actioned By</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditHistory?.map((history) => (
              <TableRow key={history.id}>
                <TableCell>{history.state}</TableCell>
                <TableCell>{formatDateTime(history.timestamp)}</TableCell>

                <TableCell>
                  {convertEnumValueToReadableString(
                    history.appointmentType,
                    "-",
                  )}
                </TableCell>
                <TableCell>{formatDateTime(history.activeTo)}</TableCell>
                <TableCell>
                  <Link component={WLink} to={`/staff/${history.staffId}/`}>
                    {history.staffEmail}
                  </Link>
                </TableCell>
                <TableCell>{history.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default CreditHistory;
