import {
  SubmitButton,
  ToastContext,
  AutocompleteField,
  Value,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import MUIDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { AppointmentSlot, Patient } from "src/models";
import { useEditAppointmentMutation } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

const FormSchema = z.object({
  reason: z.string(),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.infer<typeof FormSchema>;

const editReasonOptions = [
  { value: "Clinician Unavailable" },
  { value: "Clinician Medical Issue" },
  { value: "Clinician Technical Issue" },
  { value: "Patient Unavailable" },
  { value: "Patient Medical Issue" },
  { value: "Patient Technical Issue" },
];

interface IProps {
  appointmentId: string;
  appointmentSlots: AppointmentSlot[];
  onClose: () => void;
  open: boolean;
  patient?: Patient;
}

const Dialog = ({
  appointmentId,
  appointmentSlots,
  onClose,
  open,
  patient,
}: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: EditAppointment } = useEditAppointmentMutation(
    appointmentId ?? "",
  );
  const [slotIndex, setSlotIndex] = useState(0);

  useEffect(() => setSlotIndex(0), [appointmentSlots, setSlotIndex]);

  const selectedSlot = appointmentSlots[slotIndex];

  const methods = useForm<FormType>({
    defaultValues: {
      reason: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await EditAppointment({
        appointmentType: selectedSlot!.appointmentType,
        clinicianId: selectedSlot!.clinicianId,
        patientId: patient!.id,
        reason: data.reason,
        start: selectedSlot.start,
      });
      setLocation(`/appointments/${appointmentId}/`);
      addToast("Appointment Edited", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "NOT_VALID"
      ) {
        addToast("Invalid appointment.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "SLOT_NOT_AVAILABLE"
      ) {
        addToast("Appointment slot not available.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "OVERLAPS_EXISTING_APPOINTMENT"
      ) {
        addToast("Overlaps existing appointment.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "PRICE_INCREASE"
      ) {
        addToast("Cannot replace with more expensive appointment", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "APPOINTMENT_OVER_2_DAYS_IN_PAST"
      ) {
        addToast(
          "Cannot edit appointment originally scheduled for more than 2 days in the past.",
          "error",
        );
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <MUIDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>New Appointment</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={2}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Clinician</TableCell>
                      {appointmentSlots.length > 1 ? (
                        <TableCell>
                          <Select
                            fullWidth
                            onChange={(event: SelectChangeEvent<string>) =>
                              setSlotIndex(parseInt(event.target.value))
                            }
                            size="small"
                            value={slotIndex.toString()}
                          >
                            {appointmentSlots.map((slot, index) => (
                              <MenuItem key={index} value={index.toString()}>
                                {slot.clinicianName}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Value text={selectedSlot?.clinicianName} />
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>Patient</TableCell>
                      <TableCell>
                        <Value text={patient?.name} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Appointment Type</TableCell>
                      <TableCell>
                        {selectedSlot !== undefined
                          ? convertEnumValueToReadableString(
                              selectedSlot.appointmentType,
                              "-",
                            )
                          : null}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>
                        <Value date={selectedSlot?.start} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>
                        <Value time={selectedSlot?.start} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell>
                        <Value currency={selectedSlot?.price} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <AutocompleteField
                fullWidth
                required={appointmentId !== undefined}
                freeSolo
                options={editReasonOptions}
                name="reason"
                label="Reason for Change of Appointment"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
            <SubmitButton
              disabled={patient === undefined || selectedSlot === undefined}
              label="Confirm"
            />
          </DialogActions>
        </form>
      </FormProvider>
    </MUIDialog>
  );
};

export default Dialog;
