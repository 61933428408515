import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { AppointmentCredit } from "src/models";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  credit: AppointmentCredit | undefined;
}
const CreditDetails = ({ credit }: IProps) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>
                <Value text={credit?.state} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Appointment Type</TableCell>
              <TableCell>
                <Value
                  text={convertEnumValueToReadableString(
                    credit?.appointmentType ?? "",
                    "-",
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Active From</TableCell>
              <TableCell>
                <Value dateTime={credit?.activeFrom} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Active To</TableCell>
              <TableCell>
                <Value dateTime={credit?.activeTo} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Subscription</TableCell>
              <TableCell>
                {credit?.subscriptionId ? (
                  <Link
                    component={WLink}
                    href={`/subscriptions/${credit.subscriptionId}/`}
                  >
                    {credit.subscriptionId}
                  </Link>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reason</TableCell>
              <TableCell>
                <Value text={credit?.currentHistoryReason} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CreditDetails;
