import {
  SubmitButton,
  FormLinks,
  ToastContext,
  Title,
  Value,
  TextField,
  NumberField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import {
  useChangeSubscriptionBillingDayMutation,
  usePatientQuery,
  useSubscriptionPaymentsQuery,
  useSubscriptionQuery,
} from "src/queries";

const FormSchema = z.object({
  newBillingDay: z.number().min(1).max(28),
  reason: z.string().min(2),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  subscriptionId: string;
}

const ChangeBillingDay = ({ subscriptionId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: subscription } = useSubscriptionQuery(subscriptionId);
  const { data: paymentsData } = useSubscriptionPaymentsQuery(subscriptionId);
  const { mutateAsync: changeBillingDay } =
    useChangeSubscriptionBillingDayMutation(subscriptionId);
  const { data: patient } = usePatientQuery(subscription?.patientId);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      newBillingDay: (paymentsData?.nextPaymentDate ?? new Date()).getDate(),
      reason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await changeBillingDay(data);
      addToast("Billing day changed", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title
        title="Change Billing Day"
        breadcrumbs={[
          { label: "Subscriptions", to: "/subscriptions/" },
          {
            label: `Subscription - ${patient?.name}`,
            to: `/subscriptions/${subscriptionId}/`,
          },
          { label: "Change Billing Day" },
        ]}
      />
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Details" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <Value text={subscription?.status} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>
                  <Value date={subscription?.startDate} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>
                  <Value text={subscription?.type} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Current Next Payment Date</TableCell>
                <TableCell>
                  <Value date={paymentsData?.nextPaymentDate} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              Select the new billing day below. The subscription will be
              pro-rated as a result of the change - either charging the patient
              a one-off amount covering the period between the old and the new
              billing day or adjusting their next payment for the amount that
              they are owed. Only days between 1st-28th can be chosen.
              <NumberField
                fullWidth
                name="newBillingDay"
                label="Select Billing Day"
                required
                inputProps={{ min: 1, max: 28 }}
              />
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={
                  !subscription ||
                  !paymentsData ||
                  !patient ||
                  paymentsData?.nextPaymentDate === null
                }
                label="Confirm"
              />
              <FormLinks
                links={[
                  { label: "Back", to: `/subscriptions/${subscriptionId}/` },
                ]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default ChangeBillingDay;
