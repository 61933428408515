import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { useImportAppointmentPaymentMutation } from "src/queries/appointmentPayments";

const importPaymentSchema = z.object({
  paymentIntentId: z.string(),
});

type FormType = z.input<typeof importPaymentSchema>;

interface IProps {
  appointmentId: string;
}

const ImportAppointmentPayment = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: importAppointmentPayment } =
    useImportAppointmentPaymentMutation(appointmentId);

  const methods = useForm<FormType>({
    defaultValues: {
      paymentIntentId: "",
    },
    resolver: zodResolver(importPaymentSchema),
  });

  const onSubmit = async (data: FormType) => {
    try {
      await importAppointmentPayment({
        paymentIntentId: data.paymentIntentId,
      });
      addToast("Payment imported", "success");
      setLocation(`/appointments/${appointmentId}/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "INVALID_APPOINTMENT"
      ) {
        addToast("Invalid appointment.", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "APPOINTMENT_NOT_PAYABLE"
      ) {
        addToast("Cannot attach payment to non-pending appointment", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "APPOINTMENT_PAYMENT_ALREADY_EXISTS"
      ) {
        addToast("Payment already exists for this appointment", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "PAYMENT_ATTACHED_TO_OTHER_APPOINTMENT"
      ) {
        addToast("This payment is attached to another appointment", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "PAYMENT_AMOUNT_MISTMATCH"
      ) {
        addToast("Payment amount does not match appointment price", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <Title title="Import Appointment Payment" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom>
                Import the payment for this appointment by entering the payment
                intent ID from Stripe.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                This can be found on the top right of the transaction page in
                Stripe and will always start with "pi_".
              </Typography>
              <TextField
                label="Payment Intent Id"
                name="paymentIntentId"
                fullWidth
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Import Payment" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointments/${appointmentId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default ImportAppointmentPayment;
