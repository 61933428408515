import {
  SelectField,
  Title,
  DateField,
  SubmitButton,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { addMonths, formatISO, startOfMonth } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

const REPORTS = [
  {
    label: "Appointment Capacity",
    value: "/v1/reports/appointment-capacity",
  },
  {
    label: "Sales",
    value: "/v1/reports/sales",
  },
  {
    label: "Deferred Sales",
    value: "/v1/reports/deferred-sales",
  },
  {
    label: "Clinician Appointments",
    value: "/v1/reports/clinician-appointments",
  },
  {
    label: "Payments",
    value: "/v1/reports/payments",
  },
];

const reportSchema = z.object({
  end: z.coerce.date(),
  report: z.string(),
  start: z.coerce.date(),
});

type FormType = z.input<typeof reportSchema>;
type ValidatedType = z.output<typeof reportSchema>;

const Reports = () => {
  const start = startOfMonth(new Date());
  const end = addMonths(start, 1);

  const methods = useForm<FormType>({
    defaultValues: {
      end: end,
      report: "/v1/reports/appointment-capacity",
      start: start,
    },
    resolver: zodResolver(reportSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    const url = `${data.report}/${formatISO(data.start, {
      representation: "date",
    })}/${formatISO(data.end, { representation: "date" })}/`;
    window.open(url, "_blank", "noopener");
  };

  return (
    <>
      <Title title="Reports" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <SelectField
                    hiddenLabel
                    fullWidth
                    name="report"
                    options={REPORTS}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <DateField
                    required
                    fullWidth
                    helperText="From this date, including the day itself"
                    label="Start"
                    name="start"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <DateField
                    required
                    fullWidth
                    helperText="To this date, including the day itself"
                    label="End"
                    name="end"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default Reports;
