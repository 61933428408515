import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";
import { addWeeks, subWeeks } from "date-fns";
import { useRef } from "react";

import { CalendarStyleContainer } from "src/components/CalendarStyleContainer";
import { ClinicianAvailability } from "src/models";
import { ClinicianUnavailability } from "src/models/clinicianUnavailability";
import {
  getClinicianAvailabilityEvents,
  getClinicianUnavailabilityEvents,
} from "src/utils";

interface IProps {
  clinicianAvailability: ClinicianAvailability[];
  clinicianUnavailability: ClinicianUnavailability[];
}
const AvailabilityCalendar = ({
  clinicianAvailability,
  clinicianUnavailability,
}: IProps) => {
  const currentDate = new Date();
  const calendarRef = useRef<FullCalendar>(null);
  const theme = useTheme();

  const availability = getClinicianAvailabilityEvents(
    clinicianAvailability,
    theme,
  );
  const unavailability = getClinicianUnavailabilityEvents(
    clinicianUnavailability,
    theme,
  );

  const allEvents = availability?.concat(unavailability ?? []);

  return (
    <Card>
      <CardHeader title="Availability Calendar" />
      <CardContent>
        <CalendarStyleContainer>
          <FullCalendar
            allDaySlot={false}
            events={allEvents}
            eventTimeFormat={{
              hour: "numeric",
              minute: "numeric",
            }}
            expandRows={false}
            firstDay={1}
            headerToolbar={{
              left: "today",
              center: "title",
              right: "prev,next",
            }}
            height="auto"
            initialView="timeGridWeek"
            locale="en-GB"
            plugins={[timeGridPlugin]}
            ref={calendarRef}
            slotLabelFormat={{
              timeStyle: "short",
            }}
            slotMinTime="07:00:00"
            slotMaxTime="23:00:00"
            validRange={{
              start: subWeeks(currentDate, 2),
              end: addWeeks(currentDate, 24),
            }}
          />
        </CalendarStyleContainer>
      </CardContent>
    </Card>
  );
};
export default AvailabilityCalendar;
