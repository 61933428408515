import { formatDate } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState, MouseEvent } from "react";
import { Link as WLink } from "wouter";

import { Subscription } from "src/models";

interface IProps {
  subscription: Subscription;
}

const PatientSubscriptionRow = ({ subscription }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/subscriptions/${subscription.id}/cancel/`}
        >
          Cancel
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>
          <Link component={WLink} href={`/subscriptions/${subscription.id}/`}>
            {subscription.id}
          </Link>
        </TableCell>
        <TableCell>{formatDate(subscription.startDate)}</TableCell>
        <TableCell>{subscription.type}</TableCell>
        <TableCell>{subscription.status}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PatientSubscriptionRow;
