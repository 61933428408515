import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useOutOfStockListingQuery } from "src/queries";

interface IProps {
  listingId: string;
}

const OutOfStockListingDetails = ({ listingId }: IProps) => {
  const { data: listing } = useOutOfStockListingQuery(listingId);

  if (!listing) {
    return <Skeleton height="80px" />;
  }

  return (
    <Card>
      <CardHeader title="Out of stock listing" />
      <Divider />

      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>
                <Value
                  link={{
                    label: listing.productName,
                    to: `/out-of-stock-listings/${listing.id}/`,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created</TableCell>
              <TableCell>
                <Value dateTime={listing.createdOn} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created By</TableCell>
              <TableCell>
                <Value
                  link={{
                    label: listing.createdByEmail,
                    to: `/staff/${listing.createdBy}/`,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ended</TableCell>
              <TableCell>
                {listing.endedOn ? (
                  <Value dateTime={listing.endedOn} />
                ) : (
                  "ACTIVE"
                )}
              </TableCell>
              <TableCell>
                {listing.endedBy && listing.endedByEmail ? (
                  <Value
                    link={{
                      label: listing.endedByEmail,
                      to: `/staff/${listing.endedBy}/`,
                    }}
                  />
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Patient information</TableCell>
              <TableCell>
                <Value text={listing.patientInformation} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internal information</TableCell>
              <TableCell>
                <Value text={listing.internalInformation} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OutOfStockListingDetails;
