import axios from "axios";
import { formatISO } from "date-fns";

import { Patient, newPatient } from "src/models";
import { useQuery } from "src/query";

export const usePatientSearchQuery = (
  value: string | null,
  dateOfBirth: Date | null,
) => {
  return useQuery<Patient[]>({
    queryKey: ["patients", value, dateOfBirth],
    queryFn: async () => {
      const response = await axios.get(`/v1/patients/`, {
        params: {
          value,
          dateOfBirth: dateOfBirth ? formatISO(dateOfBirth) : null,
        },
      });

      return response.data.patients.map((data: unknown) => {
        return newPatient(data);
      });
    },
    enabled: value !== null || dateOfBirth !== null,
  });
};

export const usePatientQuery = (patientId: string | undefined) => {
  return useQuery<Patient>({
    queryKey: ["patients", patientId],
    queryFn: async () => {
      const response = await axios.get(`/v1/patients/${patientId}/`);
      return newPatient(response.data);
    },
    enabled: patientId !== undefined,
  });
};
