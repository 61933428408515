import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import {
  ClinicianUnavailability,
  newClinicianUnavailability,
} from "src/models/clinicianUnavailability";

export const useCliniciansUnavailabilityQuery = (clinicianId: string) => {
  return useQuery<ClinicianUnavailability[]>({
    queryKey: ["clinicianUnavailability", clinicianId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/clinician-unavailability/clinician/${clinicianId}/`,
      );
      return response.data.unavailability.map((data: unknown) =>
        newClinicianUnavailability(data),
      );
    },
    enabled: clinicianId !== undefined,
  });
};

export const useClinicianUnavailabilityQuery = (unavailabilityId: number) => {
  return useQuery<ClinicianUnavailability>({
    queryKey: ["clinicianUnavailability", unavailabilityId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/clinician-unavailability/${unavailabilityId}/`,
      );
      return newClinicianUnavailability(response.data);
    },
    enabled: unavailabilityId !== undefined,
  });
};

interface IClinicianUnavailability {
  endAt: Date;
  reason: string;
  startAt: Date;
}

export const useCreateClinicianUnavailabilityMutation = (
  clinicianId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianUnavailability) =>
      await axios.post(`/v1/clinician-unavailability/${clinicianId}/`, {
        endAt: formatISO(data.endAt),
        reason: data.reason,
        startAt: formatISO(data.startAt),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["clinicianUnavailability", clinicianId],
      });
      queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability", clinicianId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

export const useDeactivateClinicianUnavailabilityMutation = (
  unavailabilityId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(
        `/v1/clinician-unavailability/${unavailabilityId}/deactivate/`,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["clinicianUnavailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};
