import { Title, Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import AppointmentCalendar from "src/components/AppointmentCalendar";
import { AppointmentSlot } from "src/models";
import Dialog from "src/pages/EditAppointment/Dialog";
import { useAppointmentQuery, usePatientQuery } from "src/queries";

interface IProps {
  appointmentId: string;
}

const EditAppointment = ({ appointmentId }: IProps) => {
  const [slots, setSlots] = useState<AppointmentSlot[]>([]);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { data: patient } = usePatientQuery(appointment?.patientId);

  return (
    <>
      <Dialog
        appointmentId={appointmentId}
        appointmentSlots={slots}
        onClose={() => setSlots([])}
        open={slots.length > 0}
        patient={patient}
      />
      <Title title="Edit Appointment" />
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Existing Appointment" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Clinician</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Value text={appointment?.clinicianName} />
                </TableCell>
                <TableCell>
                  <Value date={appointment?.startAt} />
                </TableCell>
                <TableCell>
                  <Value time={appointment?.startAt} />
                </TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  <Value
                    text={appointment?.type.toLowerCase().replace("_", "-")}
                  />
                </TableCell>
                <TableCell>
                  <Value currency={appointment?.price} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="Select New Appointment" sx={{ paddingBottom: 0 }} />
        <AppointmentCalendar
          appointment={appointment}
          onChoice={(slots: AppointmentSlot[]) => setSlots(slots)}
          patientId={appointment?.patientId}
        />
      </Card>
    </>
  );
};

export default EditAppointment;
