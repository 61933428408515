import {
  AutocompleteField,
  DateTimeField,
  FormLinks,
  SubmitButton,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import { startOfToday } from "date-fns";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { useClinicianQuery } from "src/queries/clinicians";
import { useCreateClinicianUnavailabilityMutation } from "src/queries/clinicianUnavailability";

const FormSchema = z.object({
  endAt: z.coerce.date(),
  reason: z.string(),
  startAt: z.coerce.date(),
});

type FormType = z.input<typeof FormSchema>;

interface IProps {
  clinicianId: string;
}

const AddUnavailability = ({ clinicianId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const { mutateAsync: addUnavailability } =
    useCreateClinicianUnavailabilityMutation(clinicianId);

  const methods = useForm<FormType>({
    defaultValues: {
      startAt: startOfToday(),
      endAt: startOfToday(),
      reason: "",
    },
    resolver: zodResolver(FormSchema),
  });
  const onSubmit = async (data: FormType) => {
    try {
      await addUnavailability({
        startAt: data.startAt,
        reason: data.reason,
        endAt: data.endAt,
      });
      addToast("Unavailability Added", "success");
      setLocation(`/clinicians/${clinicianId}/#availability`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "TIME_RANGE_OVERLAP"
      ) {
        addToast(
          "An unavailability block already exists at this time",
          "error",
        );
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "END_AT_BEFORE_START_AT"
      ) {
        addToast("End time must be after start time", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <Title
        title="Add Unavailability"
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician?.name}`, to: `/clinicians/${clinicianId}/` },
          { label: "Add Unavailability" },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                When a block of unavailability is added, any existing
                availability that it overlaps with will be altered accordingly.
              </Alert>
              <Grid container>
                <Grid size={12}>
                  <DateTimeField
                    fullWidth
                    name="startAt"
                    label="Start"
                    required
                  />
                </Grid>
                <Grid size={12}>
                  <DateTimeField fullWidth name="endAt" label="End" required />
                </Grid>
                <Grid size={12}>
                  <AutocompleteField
                    fullWidth
                    freeSolo
                    name="reason"
                    label="Reason"
                    required
                    options={[
                      { value: "Annual Leave" },
                      { value: "Bank Holiday" },
                      { value: "Training" },
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Add Unavailability" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/clinicians/${clinicianId}/#availability`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default AddUnavailability;
