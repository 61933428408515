import { Title } from "@curaleaf-international/components";
import Card from "@mui/material/Card";

import { Subscription } from "src/models";
import SubscriptionsTable from "src/pages/Subscriptions/SubscriptionsTable";
import { usePaginationSettings } from "src/pagination";

const Subscriptions = () => {
  const [paginationSettings, { setPageNumber, setPageSize, toggleSort }] =
    usePaginationSettings<Subscription>({
      pageNumber: 0,
      pageSize: 100,
      sortColumn: "startDate",
      sortDirection: "desc",
    });

  return (
    <>
      <Title title="Subscriptions" />
      <Card>
        <SubscriptionsTable
          paginationSettings={paginationSettings}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          toggleSort={toggleSort}
        />
      </Card>
    </>
  );
};

export default Subscriptions;
