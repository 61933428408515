import {
  SubmitButton,
  FormLinks,
  EmailField,
  CheckboxField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid2";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { Role } from "src/models/staff";

interface IRoles {
  [key: string]: boolean | string;
}

const FormSchema = z.object({
  email: z.string().email(),
  roles: z.record(z.string(), z.union([z.boolean(), z.string()])),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  onSubmit: (data: ValidatedType) => Promise<any>;
}
export const roles = Object.keys(Role);

const StaffMemberForm = ({ onSubmit }: IProps) => {
  const initialValues = {
    email: "",
    roles: {
      ...roles.reduce((accum: IRoles, role) => {
        accum[role] = false;
        return accum;
      }, {}),
    },
  };

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid size={12}>
                <EmailField fullWidth label="Email" name="email" required />
              </Grid>
              <Grid size={12} sx={{ marginLeft: 2 }}>
                <FormLabel sx={{ marginLeft: -1 }} required>
                  Roles
                </FormLabel>
                {roles.map((role) => (
                  <CheckboxField
                    key={role}
                    fullWidth
                    label={role}
                    name={`roles.${role}`}
                  />
                ))}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Add Staff Member" />
            <FormLinks links={[{ label: "Back", to: "/staff/" }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default StaffMemberForm;
