import * as z from "zod";

const dataPointSchema = z.object({
  count: z.number(),
  day: z.coerce.date(),
});

export type DataPoint = z.infer<typeof dataPointSchema>;

export const newDataPoint = (data: unknown): DataPoint =>
  dataPointSchema.parse(data);

const dashboardSchema = z.object({
  appointmentsOccured: z.array(dataPointSchema),
  confirmedAppointments: z.array(dataPointSchema),
  maxConfirmedAppointments: z.number(),
  assistedReferrals: z.array(dataPointSchema),
  subscriptionPayments: z.array(dataPointSchema),
});

export type Dashboard = z.infer<typeof dashboardSchema>;

export const newDashboard = (data: unknown): Dashboard =>
  dashboardSchema.parse(data);
