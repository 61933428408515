import { NumberField, SubmitButton } from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid2";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { AppointmentLength, AppointmentType } from "src/models";
import { Clinician } from "src/models/clinician";

const appointmentLengthSchema = z.object({
  appointmentType: z.nativeEnum(AppointmentType),
  buffer: z.coerce.number().int(),
  length: z.coerce.number().int().positive(),
});

const FormSchema = z.record(z.coerce.number(), appointmentLengthSchema);
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  appointmentLengths: AppointmentLength[];
  initialValues: FormType;
  clinician: Clinician;
  onSubmit: (data: ValidatedType) => Promise<any>;
}

const AppointmentLengthsForm = ({
  appointmentLengths,
  initialValues,
  clinician,
  onSubmit,
}: IProps) => {
  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            {appointmentLengths.map((apptLength) => (
              <Grid
                key={apptLength.id}
                container
                spacing={1}
                alignItems={"center"}
              >
                <Grid size={{ xs: 12, sm: 2 }}>
                  <FormLabel sx={{ textTransform: "capitalize" }}>
                    {apptLength.appointmentType.replace("_", "-").toLowerCase()}
                  </FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 5 }}>
                  <NumberField
                    sx={{ textTransform: "capitalize" }}
                    fullWidth
                    inputProps={{
                      step: 5,
                      min: 5,
                    }}
                    label={`${apptLength.appointmentType.replace("_", "-").toLowerCase()} Length`}
                    name={`[${apptLength.id}].length`}
                    required
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 5 }}>
                  <NumberField
                    sx={{ textTransform: "capitalize" }}
                    fullWidth
                    inputProps={{
                      step: 5,
                      min: 0,
                    }}
                    label={`${apptLength.appointmentType.replace("_", "-").toLowerCase()} Buffer`}
                    name={`[${apptLength.id}].buffer`}
                    required
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Confirm Changes" />
            <Button href={`/clinicians/${clinician.id}/`}>Back</Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default AppointmentLengthsForm;
