import {
  CheckboxField,
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  formatDate,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, Link as WLink } from "wouter";
import * as z from "zod";

import {
  useCancelSubscriptionMutation,
  usePatientQuery,
  useSubscriptionQuery,
} from "src/queries";

interface IProps {
  subscriptionId: string;
}

const FormSchema = z.object({
  noticePeriod: z.boolean(),
  reason: z.string().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CancelSubscription = ({ subscriptionId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: subscription } = useSubscriptionQuery(subscriptionId);
  const { mutateAsync: cancelSubscription } =
    useCancelSubscriptionMutation(subscriptionId);
  const { data: patient } = usePatientQuery(subscription?.patientId);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      noticePeriod: false,
      reason: "",
    },
  });

  const values = methods.watch();

  let submitLabel = "Cancel Immediately";
  if (values.noticePeriod) {
    submitLabel = "Cancel with Notice Period";
  }

  const onSubmit = async (data: ValidatedType) => {
    try {
      await cancelSubscription(data);
      addToast("Subscription cancelled", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  const isPaidSubscription =
    subscription?.type === "CURA_CL" || subscription?.type === "CURA_CLI";

  return (
    <>
      <Title title="Cancel subscription" />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${subscription?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{subscription?.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>
                    {subscription?.startDate
                      ? formatDate(subscription.startDate)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>{subscription?.type}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom variant="body2">
                The reason is optional.
              </Typography>
              <TextField fullWidth label="Reason" name="reason" />
              {isPaidSubscription && (
                <Grid size={{ xs: 12, sm: 3 }}>
                  <CheckboxField
                    fullWidth
                    name="noticePeriod"
                    label="Cancel with notice period"
                  />
                </Grid>
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={subscription === undefined}
                label={submitLabel}
              />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/subscriptions/${subscriptionId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CancelSubscription;
