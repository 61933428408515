import * as z from "zod";

import { AppointmentType } from "src/models/appointment";
import { AppointmentCreditState } from "src/models/appointmentCredit";

const AppointmentCreditHistorySchema = z.object({
  id: z.coerce.number().int().positive(),
  activeTo: z.coerce.date(),
  appointmentCreditId: z.string().uuid(),
  appointmentType: z.nativeEnum(AppointmentType),
  reason: z.string().nullable(),
  staffEmail: z.string().email().nullable(),
  staffId: z.coerce.number().int().positive().nullable(),
  state: z.nativeEnum(AppointmentCreditState),
  timestamp: z.coerce.date(),
});

export type AppointmentCreditHistory = z.infer<
  typeof AppointmentCreditHistorySchema
>;

export const newAppointmentCreditHistory = (
  data: unknown,
): AppointmentCreditHistory => AppointmentCreditHistorySchema.parse(data);
