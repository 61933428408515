import {
  CircularLoader,
  SelectField,
  sortByKey,
} from "@curaleaf-international/components";
import { EventInput } from "@fullcalendar/core/index.js";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactivePlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { zodResolver } from "@hookform/resolvers/zod";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import {
  addDays,
  addWeeks,
  endOfMonth,
  isEqual,
  startOfToday,
  subDays,
} from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import AppointmentSlotCalendar from "src/components/AppointmentCalendar/AppointmentSlotCalendar";
import MiniCalendarStyleContainer from "src/components/AppointmentCalendar/MiniCalendarStyleContainer";
import AppointmentTypeSelect from "src/components/AppointmentTypeSelect";
import {
  Appointment,
  AppointmentSlot,
  AppointmentStatus,
  AppointmentType,
  PatientFollowUpPreference,
  SpecialityGroup,
} from "src/models";
import {
  ClinicianTypeFilter,
  useAppointmentSlotsQuery,
  useCliniciansQuery,
  usePatientAppointmentsQuery,
  usePatientQuery,
  useSpecialityGroupsQuery,
} from "src/queries";

const FormSchema = z.object({
  appointmentType: z.nativeEnum(AppointmentType),
  clinicianIds: z.array(z.string()),
  clinicianType: z.nativeEnum(ClinicianTypeFilter),
  selectedDate: z.coerce.date(),
  specialityGroup: z.string(),
});

type FormType = z.input<typeof FormSchema>;

interface IProps {
  appointment?: Appointment;
  onChoice: (slots: AppointmentSlot[]) => void;
  patientId?: string;
}

const AppointmentCalendar = ({ appointment, onChoice, patientId }: IProps) => {
  const [clinicianOptions, setClinicianOptions] = useState<
    string[] | undefined
  >();
  const [startAt, setStartAt] = useState<Date>(
    subDays(endOfMonth(new Date()), 15),
  );
  const [endAt, setEndAt] = useState<Date>(addDays(endOfMonth(new Date()), 15));
  const { data: appointments } = usePatientAppointmentsQuery(patientId);
  const { data: clinicians } = useCliniciansQuery();
  const { data: patient } = usePatientQuery(patientId);
  const { data: specialityGroups } = useSpecialityGroupsQuery();

  const clinician = clinicians?.find(
    (clinician) => clinician.id === appointment?.clinicianId,
  );

  const currentDate = startOfToday();
  const hasHadAppointment = appointments?.some(
    (appointment) => appointment.status === AppointmentStatus.CONFIRMED,
  );
  const matchedSpecialityGroup =
    specialityGroups?.find((group: SpecialityGroup) =>
      group.associatedDiagnoses.includes(patient?.primaryDiagnosis as any),
    ) ?? undefined;
  const specialityGroupClinicianIds = matchedSpecialityGroup?.clinicians ?? [];

  let defaultAppointmentType = AppointmentType.INITIAL;
  let defaultClinicianIds = undefined;
  let defaultClinicianType = ClinicianTypeFilter.CONSULTANTS;

  const confirmedAppointments =
    appointments?.filter(
      (appointment) => appointment.status === AppointmentStatus.CONFIRMED,
    ) ?? [];

  if (hasHadAppointment && confirmedAppointments.length !== 4) {
    defaultAppointmentType = AppointmentType.FOLLOW_UP;

    switch (patient?.patientFollowupPreference) {
      case PatientFollowUpPreference.STANDARD_GROUP_PHARMACIST_OR_GP:
        defaultClinicianIds = specialityGroupClinicianIds;
        break;
      case PatientFollowUpPreference.ASSIGNED_CONSULTANT:
        defaultClinicianIds =
          patient.assignedConsultantId !== null
            ? [patient.assignedConsultantId]
            : specialityGroupClinicianIds;
        break;
      case PatientFollowUpPreference.ASSIGNED_PHARMACIST_OR_GP:
        defaultClinicianIds =
          patient.assignedPharmacistOrGpId !== null
            ? [patient.assignedPharmacistOrGpId]
            : specialityGroupClinicianIds;
        break;
      case PatientFollowUpPreference.MENTAL_HEALTH_PHARMACIST_OR_GP:
        defaultClinicianIds = specialityGroupClinicianIds?.filter((id) => {
          return matchedSpecialityGroup?.complexClinicians.includes(id);
        });
        break;
      default:
        defaultClinicianIds = specialityGroupClinicianIds;
    }
    defaultClinicianType =
      patient &&
      patient.patientFollowupPreference ===
        PatientFollowUpPreference.ASSIGNED_CONSULTANT
        ? ClinicianTypeFilter.CONSULTANTS
        : ClinicianTypeFilter.PHARMACISTS_AND_GPS;
  } else if (
    confirmedAppointments.length === 4 &&
    patient?.assignedConsultantId
  ) {
    defaultClinicianType = ClinicianTypeFilter.CONSULTANTS;
    defaultAppointmentType = AppointmentType.FOLLOW_UP;
    defaultClinicianIds = [patient.assignedConsultantId];
  } else {
    defaultClinicianIds = patient?.assignedConsultantId
      ? [patient.assignedConsultantId]
      : specialityGroupClinicianIds;
  }

  const defaultValues = {
    appointmentType: appointment?.type ?? defaultAppointmentType,
    clinicianIds:
      appointment !== undefined
        ? [appointment.clinicianId]
        : (defaultClinicianIds ??
          clinicians?.map((clinician) => clinician.id) ??
          []),
    clinicianType: clinician
      ? clinician.clinicianType === "CONSULTANT"
        ? ClinicianTypeFilter.CONSULTANTS
        : ClinicianTypeFilter.PHARMACISTS_AND_GPS
      : defaultClinicianType,
    selectedDate: currentDate,
    specialityGroup: matchedSpecialityGroup?.id.toString() ?? "All",
  };

  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [JSON.stringify(defaultValues)]);

  const {
    appointmentType,
    clinicianIds,
    clinicianType,
    selectedDate,
    specialityGroup,
  } = methods.watch();

  const theme = useTheme();

  const { data: slots } = useAppointmentSlotsQuery(
    appointmentType,
    clinicianIds,
    endAt,
    patientId,
    startAt,
  );

  const dayAvailabilities = slots?.reduce(
    (accum: Record<string, EventInput>, slot) => {
      const key = `${slot.start.toDateString()}`;
      if (!accum[key]) {
        accum[key] = {
          start: slot.start,
          allDay: true,
          display: "background",
          color: alpha(theme.palette.success.light, 0.3),
        };
      }
      return accum;
    },
    {},
  );

  const sortKey = (slot: EventInput) => {
    return [slot.clinicianName];
  };

  useEffect(() => {
    const cliniciansOptions = clinicians
      ?.filter((clinician) =>
        clinicianType === ClinicianTypeFilter.CONSULTANTS
          ? clinician.clinicianType === "CONSULTANT"
          : clinician.clinicianType !== "CONSULTANT",
      )
      .filter((clinician) =>
        specialityGroup === "All"
          ? true
          : specialityGroups
              ?.find((group) => specialityGroup === group.id.toString())
              ?.clinicians.includes(clinician.id),
      );

    if (
      clinicianType === ClinicianTypeFilter.CONSULTANTS &&
      patient?.assignedConsultantId
    ) {
      methods.setValue("clinicianIds", [patient.assignedConsultantId]);
    } else {
      methods.setValue(
        "clinicianIds",
        cliniciansOptions?.map((clinician) => clinician.id) ?? [],
      );
    }
    const cliniciansOptionsIds =
      cliniciansOptions?.map((clinician) => clinician.id) ?? [];
    setClinicianOptions(cliniciansOptionsIds);
  }, [patient, clinicianType, specialityGroup, clinicians, specialityGroups]);

  return (
    <>
      <CardContent>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid size={{ xs: 12, sm: 8 }}>
                <Stack spacing={1}>
                  <AppointmentTypeSelect
                    fullWidth
                    name="appointmentType"
                    required
                  />
                  <SelectField
                    fullWidth
                    required
                    label="Clinician Type"
                    name="clinicianType"
                    options={[
                      {
                        label: "Consultants",
                        value: ClinicianTypeFilter.CONSULTANTS,
                      },
                      {
                        label: "Pharmacists and GPs",
                        value: ClinicianTypeFilter.PHARMACISTS_AND_GPS,
                      },
                    ]}
                  />
                  <SelectField
                    fullWidth
                    required
                    label="Speciality Group"
                    name="specialityGroup"
                    options={[
                      { label: "All", value: "All" },
                      ...(specialityGroups?.map((group) => ({
                        label: group.specialityName,
                        value: group.id.toString(),
                      })) ?? []),
                    ]}
                  />
                  <SelectField
                    fullWidth
                    required
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end" sx={{ padding: 2 }}>
                            {clinicianIds.length > 0 ? (
                              <IconButton
                                onClick={() =>
                                  methods.setValue("clinicianIds", [])
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  methods.setValue(
                                    "clinicianIds",
                                    clinicianOptions ?? [],
                                  )
                                }
                              >
                                <RefreshIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                    disabled={clinicianOptions?.length === 0}
                    label="Clinicians"
                    multiple
                    name="clinicianIds"
                    options={
                      clinicians
                        ?.filter((clinician) =>
                          clinicianOptions?.includes(clinician.id),
                        )
                        .sort(sortByKey(sortKey, "asc"))
                        .map((clinician) => {
                          let label = clinician.name;
                          if (clinician.id === patient?.assignedConsultantId) {
                            label += " (assigned consultant)";
                          } else if (
                            clinician.id === appointment?.clinicianId
                          ) {
                            label += " (original clinician)";
                          }
                          return {
                            label,
                            value: clinician.id,
                          };
                        }) ?? []
                    }
                  />
                  {(slots && slots.length === 0) ||
                  clinicianIds.length === 0 ? (
                    <Alert severity="warning">
                      There are no available appointments with the current
                      selections.
                    </Alert>
                  ) : null}
                </Stack>
              </Grid>
              <Grid
                size={{ xs: 12, sm: 4 }}
                alignContent="center"
                alignItems="center"
                sx={{ position: "relative", zIndex: 1 }}
              >
                <Box
                  sx={{
                    opacity:
                      slots === undefined && clinicianIds.length !== 0
                        ? 0.3
                        : 1,
                  }}
                >
                  <MiniCalendarStyleContainer>
                    <FullCalendar
                      datesSet={(event) => {
                        if (!isEqual(endAt, event.end)) {
                          setEndAt(event.end);
                        }
                        if (!isEqual(startAt, event.start)) {
                          setStartAt(event.start);
                          methods.setValue("selectedDate", event.start);
                        }
                      }}
                      plugins={[dayGridPlugin, interactivePlugin]}
                      initialView="dayGridMonth"
                      events={Object.values(dayAvailabilities ?? [])}
                      firstDay={1}
                      selectable={true}
                      select={(event) =>
                        methods.setValue("selectedDate", event.start)
                      }
                      headerToolbar={{
                        left: "title",
                        right: "prev,next",
                      }}
                      height="auto"
                      validRange={{
                        start: currentDate,
                        end: addWeeks(currentDate, 12),
                      }}
                    />
                  </MiniCalendarStyleContainer>
                </Box>
                {slots === undefined && clinicianIds.length !== 0 ? (
                  <>
                    <Box
                      sx={{
                        zIndex: 2,
                        position: "absolute",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <CircularLoader size="small" />
                    </Box>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Divider sx={{ marginBottom: 2 }} />
        <AppointmentSlotCalendar
          clinicianIds={clinicianIds}
          onChoice={onChoice}
          selectedDate={selectedDate}
          slots={slots === undefined && clinicianIds.length === 0 ? [] : slots}
          startAt={startAt}
        />
      </CardContent>
    </>
  );
};

export default AppointmentCalendar;
