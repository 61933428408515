import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { Subscription } from "src/models";
import SubscriptionRow from "src/pages/Subscriptions/SubscriptionRow";
import { PaginationSettings } from "src/pagination";
import { useSubscriptionsQuery } from "src/queries";

interface IProps {
  paginationSettings: PaginationSettings<Subscription>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof Subscription) => void;
}

const SubscriptionsTable = ({
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
}: IProps) => {
  const { data } = useSubscriptionsQuery(paginationSettings);

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Patient</TableCell>
            <TableCell>
              <TableSortLabel<Subscription>
                columnName={"startDate"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Start Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel<Subscription>
                columnName={"type"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Type
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel<Subscription>
                columnName={"status"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows === undefined ? (
            <SkeletonRow cols={6} />
          ) : data?.rows.length === 0 ? (
            <TableRow>
              <TableCell>No subscriptions found.</TableCell>
            </TableRow>
          ) : (
            data?.rows.map((subscription: Subscription) => (
              <SubscriptionRow
                key={subscription.id}
                subscription={subscription}
              />
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination<Subscription>
        recordCount={data?.totalRecordCount}
        paginationSettings={paginationSettings}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        rowsPerPageOptions={[100, 500, 750, 1000]}
      />
    </TableContainer>
  );
};

export default SubscriptionsTable;
